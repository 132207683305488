import React, { ElementType } from 'react';
import classNames from 'classnames';
import { Combobox, ComboboxButtonProps } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { PrimitiveProps } from '../../utils/render';

export type SearchButtonProps<TTag extends ElementType> = PrimitiveProps<
  Omit<ComboboxButtonProps<TTag>, 'className'>
>;

export function SearchButton<TTag extends ElementType>({
  className,
  ...props
}: SearchButtonProps<TTag>) {
  return (
    <Combobox.Button
      className={twMerge(
        classNames(
          'flex items-center px-4 py-3 font-medium leading-5 text-indigo-600 hover:bg-indigo-50'
        ),
        className
      )}
      {...props}
    />
  );
}
SearchButton.displayName = 'Search.Button';
