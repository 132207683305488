import React from 'react';
import { useMessageGetter } from '@messageformat/react';
import classNames from 'classnames';
import { Button } from '@shape-construction/arch-ui';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { useInstallApp } from '../../../hooks/useInstallApp';
import { InstallLogo } from './InstallLogo';

interface InstallBarProps {
  isUserAuthenticated: boolean;
}

export const InstallBar = ({ isUserAuthenticated }: InstallBarProps) => {
  const messages = useMessageGetter('install');
  const isLargeScreen = useMediaQuery(breakpoints.up('lg'));
  const { isInstallSupported, isInStandaloneMode, promptToInstall } = useInstallApp();
  const hideInstallButton = !isInstallSupported || isInStandaloneMode;

  const handleInstall = () => {
    promptToInstall();
  };

  if (hideInstallButton || isLargeScreen) return null;

  return (
    <div
      className={classNames(
        'top-0 flex w-full h-16 max-h-16 my-auto items-center justify-center bg-indigo-500 text-white',
        { fixed: !isUserAuthenticated }
      )}
    >
      <div className="ml-3">
        <InstallLogo />
      </div>
      <div className="flex flex-col grow mx-3">
        <div className="text-sm font-semibold leading-5">{messages('installTitle')}</div>
        <div className="text-xs font-medium leading-4">{messages('mobileDescription')}</div>
      </div>
      <div className="mr-3">
        <Button color="white" size="xs" variant="outlined" onClick={handleInstall}>
          {messages('mobileButton')}
        </Button>
      </div>
    </div>
  );
};
