import React, { useContext, useMemo } from 'react';
import { useMessageGetter } from '@messageformat/react';
import classNames from 'classnames';
import { NavLink, useLocation, useMatch } from 'react-router-dom';
import {
  NewspaperIcon,
  ShapeIssueTrackerIcon,
  ShapeProjectGalleryIcon,
  ShapeShiftReportIcon,
} from '@shape-construction/arch-ui/src/Icons/outline';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { LayoutContext } from 'app/contexts/Layout.context';
import { QuickNewButton } from '../../QuickNewButton/QuickNewButton';

type BottomNavigationOption = {
  icon: JSX.Element;
  title: string;
  route: string;
};

export interface BottomNavigationProps {
  className?: string;
}

export const BottomNavigation: React.FC<BottomNavigationProps> = ({ className }) => {
  const { search } = useLocation();
  const messages = useMessageGetter('navigation');
  const {
    layoutConfig: { showBottomNavigation },
  } = useContext(LayoutContext);
  const isProjectRoute = useMatch({ path: '/projects/:projectId', end: false });
  const projectId = isProjectRoute?.params?.projectId;
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const isQuickNewButtonVisible = projectId && showBottomNavigation && !isLargeScreen;

  const routes: BottomNavigationOption[] = useMemo(
    () => [
      {
        icon: <NewspaperIcon className="h-6 w-6" />,
        title: messages('timeline'),
        route: search
          ? `/projects/${projectId}/timeline${search}`
          : `/projects/${projectId}/timeline`,
      },
      {
        icon: <ShapeIssueTrackerIcon className="h-6 w-6" />,
        title: messages('issues'),
        route: search ? `/projects/${projectId}/filter${search}` : `/projects/${projectId}/issues`,
      },
      {
        icon: <ShapeShiftReportIcon className="h-6 w-6" />,
        title: messages('shiftReports'),
        route: `/projects/${projectId}/shift-reports/drafts`,
      },
      {
        icon: <ShapeProjectGalleryIcon className="h-6 w-6" />,
        title: messages('gallery'),
        route: `/projects/${projectId}/gallery`,
      },
    ],
    [projectId, search, messages]
  );

  const displayMenuItems = (items: BottomNavigationOption[]): JSX.Element => (
    <div className="flex justify-evenly w-full">
      {items.map(({ icon, title, route }) => (
        <NavLink
          aria-label={title}
          to={route}
          key={title}
          className={({ isActive }) =>
            classNames(
              'flex flex-1 flex-col items-center justify-center gap-1 pt-3 pb-5 px-2 after:absolute after:top-0 after:w-[40px] after:border-t-[3px] after:content-[""]',
              {
                'text-gray-400 after:border-t-transparent': !isActive,
                'text-indigo-500 after:border-t-indigo-500': isActive,
              }
            )
          }
        >
          {icon}
          <span className="text-xs font-medium leading-5 whitespace-nowrap">{title}</span>
        </NavLink>
      ))}
    </div>
  );

  if (!isProjectRoute || !showBottomNavigation) return null;

  return (
    <nav
      aria-label="bottom navigation"
      data-testid="res-bottom-nav"
      className={classNames(
        'sticky inset-x-0 bottom-0 z-navigation flex items-center justify-between border-t border-t-gray-100 bg-white',
        className
      )}
    >
      {displayMenuItems(routes.slice(0, 2))}
      {isQuickNewButtonVisible && (
        <div className="flex flex-1 justify-center pb-3">
          <QuickNewButton projectId={projectId} />
        </div>
      )}
      {displayMenuItems(routes.slice(2))}
    </nav>
  );
};
