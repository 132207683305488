import React, { useMemo, useState } from 'react';
import { useMessageGetter } from '@messageformat/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  GetApiProjectsProjectIdDocumentsParams,
  ProjectSchema,
  DocumentSchema,
} from '@shape-construction/api/model';
import { Button, FullScreenModal } from '@shape-construction/arch-ui';
import { FullScreenModalProps } from '@shape-construction/arch-ui/src/FullScreenModal/FullScreenModal';
import { GalleryPicker, GalleryPickerProps } from 'app/components/Gallery/GalleryPicker';
import { InfiniteLoadWaypoints } from 'app/components/InfiniteLoadWaypoints/InfiniteLoadWaypoints';

import { getProjectDocumentsInfiniteQueryOptions } from 'app/queries/projectDocuments/projectDocuments';
import { useProject } from 'app/queries/projects/projects';

import { EmptyResultsClear } from './components/GalleryGrid/EmptyResultsClear';
import {
  GalleryGridItem,
  GalleryGridToolbar,
  GallerySkeleton,
} from './components/GalleryGrid/GalleryGrid';
import { useDateFiltersFromGallery } from './components/GalleryToolbar/DateSelect/useDateFiltersFromGallery';
import {
  GalleryFilterForm,
  GalleryFilterFormValues,
} from './components/GalleryToolbar/GalleryFilterForm';
import {
  GalleryFilterFormAuthors,
  GalleryFilterFormDate,
  GalleryFilterFormFormat,
  GalleryFilterFormLocations,
} from './components/GalleryToolbar/GalleryFilterFormFields';
import { useGalleryStateParams } from './components/GalleryToolbar/useGalleryParams';

export type GalleryPickerModalProps = {
  projectId: ProjectSchema['id'];
  projectDocumentsParams?: GetApiProjectsProjectIdDocumentsParams;
  open: FullScreenModalProps['open'];
  onClose: FullScreenModalProps['onClose'];
  excluded?: DocumentSchema['id'][];
  multiple?: GalleryPickerProps['multiple'];
  value?: GalleryPickerProps['value'];
  onSubmit: (ids: GalleryPickerProps['value']) => void;
};

export const GalleryPickerModal = ({
  open,
  onClose,
  multiple,
  projectId,
  excluded = [],
  value: defaultValue,
  projectDocumentsParams,
  onSubmit,
}: GalleryPickerModalProps) => {
  const messages = useMessageGetter('galleryPicker.modal');
  const [value, setValue] = useState<GalleryPickerProps['value']>(defaultValue || []);
  const { data: project } = useProject(projectId);
  const { filters, sort, updateParams } = useGalleryStateParams();
  const dates = useDateFiltersFromGallery(filters, project);
  const disableFormat = Boolean(projectDocumentsParams?.kind);
  const defaultValues: GalleryFilterFormValues = useMemo(
    () => ({
      date: {
        date: filters.date,
        endDate: filters.endDate,
        relativeDate: filters.relativeDate,
      },
      order: sort.order,
      authors: filters.authors,
      format: filters.format,
      locations: filters.locations,
    }),
    [filters, sort]
  );
  const {
    data: documents,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    ...getProjectDocumentsInfiniteQueryOptions(projectId, {
      location_id: filters.locations,
      created_start: dates.date,
      created_end: dates.endDate,
      team_member_id: filters.authors as unknown as number[],
      kind: projectDocumentsParams?.kind || filters.format,
      sort_order: sort.order,
    }),
    select: (result) =>
      (result?.pages.map(({ entries }) => entries).flat() || []).filter(
        (doc) => !excluded.includes(doc.id)
      ),
  });

  const hasDocuments = documents && documents.length > 0;
  const handleSubmit = () => {
    onSubmit(value);
    onClose();
    setValue([]);
  };

  const applyFilter = ({ date, ...params }: GalleryFilterFormValues) =>
    updateParams({ ...date, ...params });

  const renderGalleryList = () => {
    if (isLoading) return <GallerySkeleton length={24} />;
    if (!hasDocuments) return <EmptyResultsClear />;

    return (
      <GalleryPicker multiple={multiple} documents={documents} value={value} onChange={setValue}>
        {(itemProps) => {
          const { document, index } = itemProps;
          const isFirstEvent = index === 0;
          const isLastEvent = index === documents.length - 1;

          if (!document) return null;
          if (isFetchingNextPage && isLastEvent)
            return <GallerySkeleton key={document.id} className="contents" length={24} />;

          return (
            <InfiniteLoadWaypoints
              key={document.id}
              hasNextPage={hasNextPage}
              fetchNext={fetchNextPage}
              isFirst={isFirstEvent}
              isLast={isLastEvent}
            >
              <GalleryGridItem {...itemProps} />
            </InfiniteLoadWaypoints>
          );
        }}
      </GalleryPicker>
    );
  };

  return (
    <GalleryFilterForm defaultValues={defaultValues} onSubmit={applyFilter}>
      <FullScreenModal open={open} onClose={onClose}>
        <FullScreenModal.Header onClose={onClose}>
          <FullScreenModal.Title>{messages('title')}</FullScreenModal.Title>
        </FullScreenModal.Header>
        <GalleryGridToolbar className="border-b" onChange={applyFilter}>
          <GalleryFilterFormDate />
          {!disableFormat && <GalleryFilterFormFormat />}
          <GalleryFilterFormLocations />
          <GalleryFilterFormAuthors />
        </GalleryGridToolbar>
        <FullScreenModal.Content className="py-4">{renderGalleryList()}</FullScreenModal.Content>
        <FullScreenModal.Footer>
          <p className="mr-auto">{messages('selected', { COUNT: value.length })}</p>
          <Button
            type="reset"
            color="secondary"
            size="md"
            variant="outlined"
            onClick={() => onClose()}
          >
            {messages('cancelCTA')}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="md"
            disabled={value.length === 0}
            onClick={handleSubmit}
          >
            {messages('addCTA')}
          </Button>
        </FullScreenModal.Footer>
      </FullScreenModal>
    </GalleryFilterForm>
  );
};
