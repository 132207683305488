import axios, { type AxiosRequestConfig } from 'axios';
import SparkMD5 from 'spark-md5';
import { postApiDirectUploadsType } from '@shape-construction/api/api';
import type { DirectUploadTypeSchema } from '@shape-construction/api/model';
import { onResponseError } from 'app/axios-interceptor';

const camelCaseToDashcase = (name: string) => name.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`);

const axiosInstance = axios.create();
axiosInstance.interceptors.response.use(undefined, onResponseError);

class FileChecksum {
  file: File;
  chunkSize: number;
  chunkCount: number;
  chunkIndex: number;
  resolve?: ((base64digest: string) => void) | undefined;
  reject?: (error: Error) => void | undefined;
  md5Buffer?: SparkMD5.ArrayBuffer | undefined;
  fileReader: FileReader | undefined;

  static create(file: File) {
    const instance = new FileChecksum(file);
    return new Promise<string>((resolve, reject) => {
      instance.create(resolve, reject);
    });
  }

  constructor(file: File) {
    this.file = file;
    this.chunkSize = 2097152;
    this.chunkCount = Math.ceil(this.file.size / this.chunkSize);
    this.chunkIndex = 0;
  }

  create(resolve: (base64digest: string) => void, reject: (error: Error) => void) {
    this.resolve = resolve;
    this.reject = reject;
    this.md5Buffer = new SparkMD5.ArrayBuffer();
    this.fileReader = new FileReader();
    this.fileReader.addEventListener('load', (event) => this.fileReaderDidLoad(event));
    this.fileReader.addEventListener('error', (event) => this.fileReaderDidError(event));
    this.readNextChunk();
  }

  fileReaderDidLoad(event: ProgressEvent<FileReader>) {
    if (!this.md5Buffer) return;

    this.md5Buffer.append(event.target?.result as ArrayBuffer);

    if (!this.readNextChunk()) {
      const binaryDigest = this.md5Buffer.end(true);
      const base64digest = btoa(binaryDigest);
      this.resolve?.(base64digest);
    }
  }

  fileReaderDidError(event: ProgressEvent<FileReader>) {
    if (event.target?.error) this.reject?.(event.target.error);
    else this.reject?.(new Error('Unhandled error'));
  }

  readNextChunk() {
    if (this.chunkIndex < this.chunkCount || (this.chunkIndex === 0 && this.chunkCount === 0)) {
      const start = this.chunkIndex * this.chunkSize;
      const end = Math.min(start + this.chunkSize, this.file.size);
      const bytes = this.file.slice(start, end);
      this.fileReader?.readAsArrayBuffer(bytes);
      // eslint-disable-next-line no-plusplus
      this.chunkIndex++;
      return true;
    }
    return false;
  }
}

type BlobAttributes = {
  filename: string;
  content_type: string;
  byte_size: number;
  checksum: string;
};

export const directUpload = async (
  file: File,
  type: DirectUploadTypeSchema,
  options?: AxiosRequestConfig
) => {
  const checksum = await FileChecksum.create(file);
  const blobAttributes: BlobAttributes = {
    filename: file.name,
    content_type: file.type || 'application/octet-stream',
    byte_size: file.size,
    checksum,
  };
  const blob = await postApiDirectUploadsType(type, { blob: blobAttributes });
  const directUploadData = blob.directUpload;
  const mappedHeaders = Object.entries(directUploadData.headers).reduce(
    (accumulator, [key, value]) => ({
      [camelCaseToDashcase(key)]: value,
      ...accumulator,
    }),
    options?.headers || ({} as Record<string, string>)
  );

  await axiosInstance.put(directUploadData.url, file, {
    ...options,
    headers: mappedHeaders,
  });

  return blob;
};
