import React from 'react';
import { Drawer, DrawerProps } from '../Drawer';
import { CircleDotIcon } from '../Icons/solid';
import { ArrowTopRightOnSquareIcon, EnvelopeIcon } from '../Icons/outline';
import Link from '../Link';
import Alert from '../Alert';
import Button from '../Button';
import { Article, Section } from './types';
import { ArticleDetails } from './components/ArticleDetails';
import { ListArticlesContainer } from './components/ListArticlesContainer';
import { ListSections, ListSectionsSkeleton } from './components/ListSections';

export type HelpCenterProps = Pick<DrawerProps, 'open' | 'onClose'> & {
  articles: Article[];
  sections: Section[];
  helpCenterUrl: string;
  helpCenterEmail: string;
  selectedArticle: Article | undefined;
  selectedSection: Section | undefined;
  onSelectArticle: (article: Article | undefined) => void;
  isLoading?: boolean;
  showProductTipsAlert?: boolean;
  seeTips?: () => void;
};

export const HelpCenter: React.FC<HelpCenterProps> = ({
  articles,
  sections,

  selectedSection,
  selectedArticle,
  onSelectArticle,

  helpCenterUrl,
  helpCenterEmail,

  isLoading = false,

  showProductTipsAlert = false,
  seeTips,
  ...drawerProps
}) => {
  return (
    <Drawer {...drawerProps}>
      <Drawer.Header onClose={drawerProps.onClose}>
        <Drawer.Title>Help & Tips</Drawer.Title>
      </Drawer.Header>
      <Drawer.Content>
        {showProductTipsAlert && (
          <Alert
            color="discovery"
            emphasis="bold"
            rounded={false}
            displayInRow
            customIcon={<CircleDotIcon className="h-5 w-5text-white" />}
          >
            <Alert.Message>Want to see the highlight tips again?</Alert.Message>
            <Alert.Actions>
              <Button variant="outlined" color="white" size="xs" onClick={seeTips}>
                See tips
              </Button>
            </Alert.Actions>
          </Alert>
        )}
        {selectedArticle ? (
          <div data-testid="article section">
            <ArticleDetails
              onNavigateBack={() => onSelectArticle(undefined)}
              article={selectedArticle}
            />
          </div>
        ) : (
          <>
            <ListArticlesContainer
              isLoading={isLoading}
              articles={articles}
              helpCenterUrl={helpCenterUrl}
              helpCenterEmail={helpCenterEmail}
              selectedSection={selectedSection}
              onSelectArticle={onSelectArticle}
            />
            {isLoading ? (
              <ListSectionsSkeleton />
            ) : (
              <ListSections title="Learn more about Shape" sections={sections} />
            )}
          </>
        )}
      </Drawer.Content>
      <Drawer.Footer>
        <div className="flex gap-x-6 text-sm font-medium leading-5">
          <Link
            href={`mailto:${helpCenterEmail}`}
            target="_blank"
            rel="noreferrer"
            color="primary"
            leadingIcon={EnvelopeIcon}
          >
            Contact support
          </Link>
          <Link
            href={helpCenterUrl}
            target="_blank"
            rel="noreferrer"
            color="primary"
            leadingIcon={ArrowTopRightOnSquareIcon}
          >
            Go to help center
          </Link>
        </div>
      </Drawer.Footer>
    </Drawer>
  );
};
