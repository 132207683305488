import { type DateRange } from '@shape-construction/arch-ui/src/DatePickerCalendar';
import { type DateType, now, parseDate } from '@shape-construction/utils/DateTime';

export type PredefinedOptionKey =
  | 'all'
  | 'today'
  | 'yesterday'
  | 'thisWeek'
  | 'thisMonth'
  | 'custom';

export type PredefinedOption = {
  label: string;
  key: PredefinedOptionKey;
  custom?: boolean;
  value: (from?: DateType, to?: DateType) => DateRange;
};

export const datePickerPredefinedOptionsMap: Record<PredefinedOptionKey, PredefinedOption> = {
  all: {
    key: 'all',
    label: 'All',
    value: () => ({
      from: undefined,
      to: undefined,
    }),
  },
  today: {
    key: 'today',
    label: 'Today',
    value: () => {
      return {
        from: now().startOf('day').toDate(),
        to: now().endOf('day').toDate(),
      };
    },
  },
  yesterday: {
    key: 'yesterday',
    label: 'Yesterday',
    value: () => {
      const yesterday = now().subtract(1, 'day');
      return {
        from: yesterday.startOf('day').toDate(),
        to: yesterday.endOf('day').toDate(),
      };
    },
  },
  thisWeek: {
    key: 'thisWeek',
    label: 'This week',
    value: () => ({
      from: now().startOf('week').toDate(),
      to: now().endOf('day').toDate(),
    }),
  },
  thisMonth: {
    key: 'thisMonth',
    label: 'This month',
    value: () => ({
      from: now().startOf('month').toDate(),
      to: now().endOf('day').toDate(),
    }),
  },
  custom: {
    key: 'custom',
    label: 'Custom',
    custom: true,
    value: (from?: DateType, to?: DateType) => ({
      from: from ? parseDate(from).startOf('day').toDate() : undefined,
      to: to ? parseDate(to).endOf('day').toDate() : undefined,
    }),
  },
};

export const datePickerPredefinedOptions: PredefinedOption[] = Object.entries(
  datePickerPredefinedOptionsMap
).map(([key, value]) => {
  return {
    ...value,
    key: key as PredefinedOptionKey,
  };
});
