import { booleanFlag, config } from 'app/featureFlags/utils/config-helpers';

/**
 * Feature flags configuration.
 *
 * @see {import('./types').FlagConfig} for more information on the configuration options.
 *
 * @example
 * ## For boolean flags -
 * ```js
 * config({
 *   // ...
 *   dark_mode: booleanFlag({ expiry: '2023-12-15' }),
 * })
 * ```
 *
 * ## For variant flags -
 * ```js
 * config({
 *   // ...
 *   new_issue_redesign: variantFlag<'A' | 'B'>({ // Variant keys for better type information
 *     target: 'team', // Targets team for gradual rollout and A/B
 *     default: 'B', // Default value for flag
 *     expiry: '2023-12-15',
 *   }),
 * })
 * ```
 */
export const FEATURE_FLAGS_CONFIG = config({
  'activities-import-export': booleanFlag({
    expiry: '2024-11-24',
  }),
  'weekly-work-planner': booleanFlag({
    expiry: '2024-10-24',
  }),
  'install-shape-popover': booleanFlag({
    expiry: '2024-12-24',
  }),
  'cookiebot': booleanFlag({
    expiry: '2024-10-24',
    allowSessionFallback: true,
  }),
  'issue-status-statement': booleanFlag({
    expiry: '2024-10-25',
  }),
  'shift-report-resources': booleanFlag({
    expiry: '2024-10-25',
  }),
  'resources-admin': booleanFlag({
    expiry: '2024-10-25',
  }),
  'shift-report-pdf-export': booleanFlag({
    expiry: '2024-10-25',
  }),
  'notifications-popover': booleanFlag({
    expiry: '2024-10-25',
  }),
  'product-tours': booleanFlag({
    expiry: '2025-01-03',
  }),
});

export type FlagName = keyof typeof FEATURE_FLAGS_CONFIG;
