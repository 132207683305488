import type { LocationSchema } from '@shape-construction/api/model';
import type { NodeModel, TreeProps } from '@shape-construction/arch-ui/src/Tree/Tree';
import { createItem } from '@shape-construction/arch-ui/src/Tree/tree-utils';
import { Node } from '@shape-construction/arch-ui/src/TreeSelector/TreeSelector';

export const rootId = 'root';

type LocationNodeData = LocationSchema & {
  index: number;
  length: number;
};

const getLocation = (locations: LocationSchema[] = [], locationId: LocationSchema['id'] | null) => {
  if (!locationId) return null;

  return locations.find(({ id }) => id === locationId);
};

/**
 * Retrieve the all the parent short codes for a given location as a string
 *
 * @param locations: id indexed associative array of location objects
 * @param {string} locationId: location GUID
 * @return {string} path. ex: 'RAI > KGS > Level 1'
 */
export const fullLocationPath = (
  locations: LocationSchema[] = [],
  locationId: LocationSchema['id']
): string => locationListToPath(fullLocationsList(locations, locationId));

/**
 * Retrieve the all the parent short codes for a given location.
 *
 * @param locations: id indexed associative array of location objects
 * @param {string} locationId: location GUID
 * @return {string[]} path list. ex: ['RAI', 'KGS', 'Level 1']
 */
export const fullLocationsList = (
  locations: LocationSchema[] = [],
  locationId: LocationSchema['id']
): string[] => {
  const pathLocations = buildPath(locations, locationId);

  return pathLocations.map((location, index) =>
    index === pathLocations.length - 1 ? location.name : location.shortCode
  );
};

/**
 * Retrieve the all the parent short codes for a given location as a string
 *
 * @param locations: id indexed associative array of location objects
 * @param {string} locationId: location GUID
 * @return {string} path. ex: 'Root > Level 0 > Level 1'
 */
export const fullLongLocationPath = (
  locations: LocationSchema[] = [],
  locationId: LocationSchema['id'] | null
) => {
  if (!locationId) return null;

  return locationListToPath(fullLongLocationsList(locations, locationId));
};

/**
 * Retrieve the all the parent location names for a given location.
 *
 * @param locations: id indexed associative array of location objects
 * @param {string} locationId: location GUID
 * @return {string[]} path list. ex: ['Root', 'Level 0', 'Level 1']
 */
export const fullLongLocationsList = (
  locations: LocationSchema[],
  locationId: LocationSchema['id'] | null
) => {
  if (!locationId) return [];

  return buildPath(locations, locationId).map(({ name }) => name);
};

/**
 * Get the truncated location path.
 *
 * @param locations: id indexed associative array of location objects
 * @param {string} locationId: location GUID
 * @return {string} truncated path. ex: '..LEV1 > AREWES > LOB'
 */
export const truncatedLocationPath = (
  locations: LocationSchema[] = [],
  locationId: LocationSchema['id'] | null
) => {
  if (!locationId) return null;

  return locationListToPath(truncatedLocationsList(locations, locationId));
};

/**
 * Get the truncated location path for the issues screen as an array of short names.
 *
 * @param locations: id indexed associative array of location objects
 * @param {string} locationId: location GUID
 * @return {string[]} truncated path. ex: ['..LEV1', 'AREWES', 'LOB']
 */
export const truncatedLocationsList = (
  locations: LocationSchema[] = [],
  locationId: LocationSchema['id'] | null
) => {
  if (!locationId) return [];

  const truncatedArrayLength = 3;
  const pathLocations = buildPath(locations, locationId);

  const truncatedLocationsShortCode = pathLocations
    .slice(-truncatedArrayLength)
    .map((location) => location.shortCode);

  if (pathLocations.length > truncatedArrayLength) {
    truncatedLocationsShortCode[0] = `..${truncatedLocationsShortCode[0]}`;
  }
  return truncatedLocationsShortCode;
};

/**
 * Convert an array of location names or short codes into a path string.
 *
 * @param {string[]} locationList list of location names or short codes. ex: ['..LEV1', 'AREWES', 'LOB']
 * @returns {string} ex: '..LEV1 > AREWES > LOB'
 */
const locationListToPath = (locationList: string[]): string => locationList.join(' > ');

/**
 * Build an array of locations from the project's root location to the target location.
 *
 * @param {Object} locations: Map of all locations keyed by the location's ID
 * @param {string} buildLocationId: the current location ID to build up the location path from
 * @returns {string[]} An array of locations representing the path from the root to the target location node.
 */
const buildPath = (
  locations: LocationSchema[] = [],
  buildLocationId: LocationSchema['id'] | null
): LocationSchema[] => {
  if (!buildLocationId) return [];

  const location = getLocation(locations, buildLocationId);

  if (!location) return [];

  return [...buildPath(locations, location.parentLocationId), location];
};

export const buildLocationTree = (
  locations: LocationSchema[]
): TreeProps<LocationNodeData>['data'] =>
  locations.map((location) => {
    const siblingsIds = getLocationSiblingsIds(locations, location.id);
    const index = siblingsIds.indexOf(location.id);
    return createItem({
      id: location.id,
      text: location.name,
      parent: location.parentLocationId || rootId,
      data: {
        ...location,
        index,
        length: siblingsIds.length,
      },
    });
  });

export const LOCATION_SHORTCODE_LIMIT = 7;

/**
 * Function to generate the short code for the location name. It has a limit of 7 characters.
 * If the name is one word, return the first 7 letters
 * If it's two words, returns the first three letters of each word, and then limit by 7 characters
 * If it's three words, returns the first two letters of each word, and then limit by 7 characters
 * Otherwise, return the first letter of each word, and then limit by 7 characters
 *
 * @param {string} locationName - The name of the location.
 * @returns A string of the first letters of each word in the name.
 */
export const generateShortCodeFromName = (locationName: string) => {
  if (locationName === '') return locationName;

  const shortCodeWords = locationName.replace(/[^A-Za-z0-9\s]/g, '').split(/\W+/g);

  let newShortCode: string[] = [];

  switch (shortCodeWords.length) {
    case 1:
      newShortCode = shortCodeWords;
      break;
    case 2:
      newShortCode = shortCodeWords.map((str: string) => str.substring(0, 3));
      break;
    case 3:
      newShortCode = shortCodeWords.map((str: string) => str.substring(0, 2));
      break;
    default:
      newShortCode = shortCodeWords.map((str: string) => str[0]);
      break;
  }

  return newShortCode.join('').substring(0, LOCATION_SHORTCODE_LIMIT).toUpperCase();
};

/**
 * "Given a list of locations and a location id, return the list of locations that have the same parent
 * location id as the given location id."
 *
 * @param {LocationSchema[]} locations - LocationSchema[] - this is the array of locations that we're
 * going to filter through
 * @param locationId - The id of the location you want to get the siblings of.
 * @returns An array of locations that have the same parentLocationId as the locationId passed in.
 */
export const getLocationSiblings = (
  locations: LocationSchema[],
  locationId: LocationSchema['id']
) => {
  const parentLocationId = getParentIdByLocationId(locations, locationId);

  return locations.filter((location) => location.parentLocationId === parentLocationId);
};

/**
 * "Given a list of locations and a location id, return the ids of the location's siblings."
 *
 * @param {LocationSchema[]} locations - LocationSchema[]
 * @param locationId - The id of the location you want to get the siblings of.
 * @returns An array of location ids
 */
export const getLocationSiblingsIds = (
  locations: LocationSchema[],
  locationId: LocationSchema['id']
) => {
  const siblings = getLocationSiblings(locations, locationId);
  return siblings.map((location) => location.id);
};

/**
 * "Given a list of locations and a location id, return the parent location id of the location with the
 * given id."
 *
 * @param {LocationSchema[]} locations - LocationSchema[] - this is the array of locations that we're
 * searching through
 * @param locationId - The id of the location you want to find the parent of
 */
export const getParentIdByLocationId = (
  locations: LocationSchema[],
  locationId: LocationSchema['id']
) => locations.find((location) => location.id === locationId)?.parentLocationId;

export const mapToTreeSelector = (locations: LocationSchema[]): Node[] => {
  return locations.map((location) => {
    const nodeChildren = locations.filter(
      ({ parentLocationId }) => parentLocationId === location.id
    );

    return {
      id: location.id,
      name: location.name,
      parentId: location.parentLocationId,
      shortCode: location.shortCode,
      hasChildren: nodeChildren.length > 0,
    };
  });
};

export const isFirstLocationChild = (node: NodeModel<LocationNodeData>) => node.data?.index === 0;

export const isLastLocationChild = (node: NodeModel<LocationNodeData>) =>
  node.data?.index === Number(node.data?.length ?? 0) - 1;
