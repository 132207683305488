import React, { ComponentProps } from 'react';
import classNames from 'classnames';
import { useDragOver } from '@minoru/react-dnd-treeview';
import type { NodeModel, RenderParams } from '@minoru/react-dnd-treeview/dist/types';
import { ChevronDownIcon, ChevronRightIcon } from '../../Icons/outline';
import { DoubleCircleIcon, DragIcon } from '../../Icons/custom';
import { IconButton } from '../../Button/IconButton/IconButton';
import InputCheckbox from '../../InputCheckbox';
import { TreeItemContent } from './TreeItemContent';

export const IDENT_MARGIN = 24;

type ToggleButtonProps = {
  isOpen: boolean;
  toggle: () => void;
};

const ToggleButton: React.FC<ToggleButtonProps> = ({ toggle, isOpen }) => {
  const Icon = isOpen ? ChevronDownIcon : ChevronRightIcon;

  return (
    <IconButton
      aria-label="toggle"
      aria-expanded={isOpen}
      onClick={toggle}
      size="xxs"
      color="secondary"
      type="button"
      variant="outlined"
      icon={Icon}
    />
  );
};

type TreeItemParams<T> = Pick<
  RenderParams,
  'depth' | 'isOpen' | 'hasChild' | 'onToggle' | 'draggable'
> & {
  checked: boolean;
  onCheck: (node: NodeModel<T>, checked: boolean) => void;
};

export type TreeItemProps<T = unknown> = ComponentProps<'div'> & {
  node: NodeModel<T>;
  params: TreeItemParams<T>;
  children?: React.ReactNode;
};

export const TreeItem = <T,>({ children, node, params, ...divProps }: TreeItemProps<T>) => {
  const { depth, isOpen, hasChild, onToggle, draggable, checked, onCheck } = params;
  const level = depth + 1;
  const dragOverProps = useDragOver(node.id, isOpen, onToggle);

  return (
    <div
      {...divProps}
      role="treeitem"
      aria-selected={checked}
      aria-expanded={isOpen}
      aria-level={level}
      style={{ marginInlineStart: depth * IDENT_MARGIN }}
      className="flex h-auto md:h-11 flex-row items-center mb-2"
      {...dragOverProps}
    >
      <div className="flex h-full w-11 items-center justify-center shrink-0">
        {hasChild && <ToggleButton isOpen={isOpen} toggle={onToggle} />}
        {!hasChild && <DoubleCircleIcon className="text-gray-200 m-1.5" />}
      </div>

      <div
        className={classNames(
          'w-full h-full flex flex-row items-center justify-between gap-x-1 rounded border min-w-[228px] min-h-[44px]',
          { 'bg-gray-50 border-gray-200 hover:bg-gray-100': !checked },
          { 'bg-indigo-100 border-indigo-200 hover:bg-indigo-200': checked }
        )}
      >
        <div className="flex flex-row items-center">
          {draggable && (
            <button className="cursor-move hidden md:block">
              <DragIcon className="text-gray-400" aria-label="Drag location" />
            </button>
          )}
          <div className="ml-2">
            <InputCheckbox
              name="select"
              checked={checked}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onCheck?.(node, event.target.checked)
              }
            />
          </div>
        </div>
        {children ?? <TreeItemContent>{node.text}</TreeItemContent>}
      </div>
    </div>
  );
};
