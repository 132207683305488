import React, {
  ComponentPropsWithoutRef,
  createContext,
  forwardRef,
  useContext,
  useId,
  useRef,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { Slot } from '@radix-ui/react-slot';
import { useComposedRefs } from '@radix-ui/react-compose-refs';

const FileUploadProvider = createContext<{
  id?: string;
  inputRef: React.RefObject<React.ElementRef<'input'>>;
}>({
  inputRef: { current: null },
});

type FileUploadRootProps = Omit<ComponentPropsWithoutRef<'input'>, 'onChange'> & {
  onChange?: (files: File[]) => void;
};
export const FileUploadRoot = forwardRef<React.ElementRef<'input'>, FileUploadRootProps>(
  ({ children, className, onChange, id, ...inputProps }, ref) => {
    const randomId = useId();
    const elementId = id || randomId;
    const inputRef = useRef<React.ElementRef<'input'>>(null);
    const composedRef = useComposedRefs(ref, inputRef);

    const handleChange: ComponentPropsWithoutRef<'input'>['onChange'] = (event) => {
      const eventFiles = event.target.files;
      if (!eventFiles) return;

      onChange?.(Array.from(eventFiles));
      if (inputRef.current) inputRef.current.value = '';
    };

    return (
      <FileUploadProvider.Provider value={{ inputRef, id: elementId }}>
        <div className={className}>
          <input
            ref={composedRef}
            type="file"
            hidden
            id={elementId}
            onChange={handleChange}
            {...inputProps}
          />
          {children}
        </div>
      </FileUploadProvider.Provider>
    );
  }
);
FileUploadRoot.displayName = 'FileUpload.Root';

export const FileUploadTrigger = ({
  asChild,
  onClick,
  ...props
}: ComponentPropsWithoutRef<'button'> & { asChild?: boolean }) => {
  const Comp = asChild ? Slot : 'button';
  const { inputRef } = useContext(FileUploadProvider);

  const handleClick: ComponentPropsWithoutRef<'button'>['onClick'] = (event) => {
    onClick?.(event);
    inputRef.current?.click();
  };

  return <Comp onClick={handleClick} {...props} />;
};
FileUploadTrigger.displayName = 'FileUpload.Trigger';

export const FileUploadLabel = ({ className, ...props }: ComponentPropsWithoutRef<'label'>) => {
  const { id } = useContext(FileUploadProvider);
  const callbackProps: Pick<ComponentPropsWithoutRef<'label'>, 'onClick'> = {
    onClick: (e) => e.stopPropagation(),
  };

  return (
    <label
      htmlFor={id}
      className={twMerge('cursor-pointer flex flex-col items-center justify-center', className)}
      {...callbackProps}
      {...props}
    />
  );
};
FileUploadLabel.displayName = 'FileUpload.Label';
