import { createSlice } from '@reduxjs/toolkit';

export interface InstallDialogState {
  isOpen: boolean;
}

const initialState = {
  isOpen: false,
} as InstallDialogState;

const installDialogSlice = createSlice({
  name: 'installDialog',
  initialState,
  reducers: {
    openInstallDialog(state: InstallDialogState) {
      state.isOpen = true;
    },
    closeInstallDialog(state: InstallDialogState) {
      state.isOpen = false;
    },
  },
});

export const { openInstallDialog, closeInstallDialog } = installDialogSlice.actions;
export default installDialogSlice.reducer;
