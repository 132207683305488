import { useEffect, useState, useRef } from 'react';
import * as Sentry from '@sentry/react';
import { useCookieConsent } from 'app/analytics/hooks/useCookieConsent';
import { trackerTypes } from 'app/analytics/trackingConfig';
import { environment } from 'app/config/environment';

export const useSentry = () => {
  const { consentData } = useCookieConsent();
  const [isInitialized, setIsInitialized] = useState(false);
  const singletonRef = useRef(false);

  useEffect(() => {
    if (!singletonRef.current && environment.SENTRY_DSN && consentData?.[trackerTypes.sentry]) {
      Sentry.init({
        dsn: environment.SENTRY_DSN,
        environment: environment.SENTRY_ENVIRONMENT,
        release: environment.VERSION,
        replaysSessionSampleRate: 0.05,
        replaysOnErrorSampleRate: 1.0,
        integrations: [new Sentry.Replay()],
      });
      setIsInitialized(true);
      singletonRef.current = true;
    }
  }, [consentData]);

  return { isInitialized };
};
