import * as React from 'react';
import classNames from 'classnames';
import type { Size } from './skeleton-config';
import { SkeletonBase, SkeletonBaseProps } from './SkeletonBase';

const sizeClasses: Record<Size, string> = {
  xxs: 'h-4',
  xs: 'h-6',
  sm: 'h-8',
  md: 'h-10',
  lg: 'h-12',
  xl: 'h-14',
  xxl: 'h-16',
};

export interface SkeletonTextProps extends SkeletonBaseProps {
  size: Size;
}

export const SkeletonText: React.FC<SkeletonTextProps> = ({ size, ...props }) => (
  <SkeletonBase {...props} className={classNames('rounded-md w-full', sizeClasses[size])} />
);
