import React, { InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { ExclamationCircleIcon } from '../Icons/solid';
import { InputBaseProps } from '../types/InputBase';

export type InputTextProps = InputBaseProps & InputHTMLAttributes<HTMLInputElement>;

export const InputText = React.forwardRef<HTMLInputElement, InputTextProps>(
  (
    {
      autoComplete,
      autoFocus = false,
      cornerAdornment,
      description,
      disabled,
      error,
      fullWidth,
      touched = true,
      id,
      label,
      name,
      onBlur,
      onChange,
      onFocus,
      required,
      placeholder,
      value,
      maxLength,
      className,
      ...inputProps
    },
    ref
  ) => {
    const showError = error && touched;

    const labelClassnames = classNames('block text-sm font-medium text-gray-700', {
      'w-full': fullWidth,
    });

    const inputClassnames = twMerge(
      classNames(
        'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm',
        {
          'pr-10 border-red-300 text-red-800 placeholder-red-300 focus:ring-red-400 focus:border-red-400':
            showError,
          'border-gray-300 placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500':
            !showError,
          'opacity-50': disabled,
        },
        className
      )
    );

    return (
      <label htmlFor={id} className={labelClassnames}>
        {(label || cornerAdornment) && (
          <div className="mb-1 flex justify-between">
            <div className="flex gap-x-1">
              {label && <div className="mr-auto">{label}</div>}
              {required && <div className="text-red-600">*</div>}
            </div>
            {cornerAdornment && <div className="ml-auto">{cornerAdornment}</div>}
          </div>
        )}
        <div className="relative">
          <input
            {...inputProps}
            ref={ref}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            className={inputClassnames}
            disabled={disabled}
            id={id}
            maxLength={maxLength}
            name={name}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            required={required}
            value={value}
            placeholder={placeholder}
            type="text"
          />
          {showError && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon className="h-5 w-5 text-red-600" aria-hidden="true" />
            </div>
          )}
        </div>
        {showError && <p className="mt-2 text-sm font-normal text-red-700">{error}</p>}
        {description && <p className="mt-2 text-sm font-normal text-gray-500">{description}</p>}
      </label>
    );
  }
);

InputText.displayName = 'InputText';
