import {
  TeamMemberRoleSchema,
  TeamMemberSchemaConstructionRole,
} from '@shape-construction/api/model';

export type RoleDetail = {
  value: string;
  label: string;
  allowedToEdit: (roleName: string) => boolean;
};

type Permissions = {
  allNames: TeamMemberRoleSchema[];
  byName: Record<TeamMemberRoleSchema, RoleDetail>;
};

export const PERMISSIONS: Permissions = {
  allNames: ['owner', 'admin', 'contributor', 'viewer'],
  byName: {
    owner: {
      value: 'owner',
      label: 'Owner',
      allowedToEdit: (roleName: string) => roleName === 'owner',
    },
    admin: {
      value: 'admin',
      label: 'Admin',
      allowedToEdit: (roleName: string) => ['owner', 'admin'].includes(roleName),
    },
    contributor: {
      value: 'contributor',
      label: 'Contributor',
      allowedToEdit: (roleName: string) => ['owner', 'admin', 'contributor'].includes(roleName),
    },
    viewer: {
      value: 'viewer',
      label: 'Viewer',
      allowedToEdit: (roleName: string) => ['owner', 'admin', 'contributor'].includes(roleName),
    },
  },
};

type ContructionRoleOption = {
  value: string;
  label: string;
  defaultPermission: RoleDetail;
};

type ContructionRoles = {
  allNames: TeamMemberSchemaConstructionRole[];
  byName: Record<NonNullable<TeamMemberSchemaConstructionRole>, ContructionRoleOption>;
};

export const CONSTRUCTION_ROLES: ContructionRoles = {
  allNames: [
    'projectManager',
    'constructionManager',
    'designQuality',
    'inspector',
    'clientManager',
    'supervisor',
    'chargehand',
    'designer',
    'labourer',
  ],
  byName: {
    projectManager: {
      value: 'projectManager',
      label: 'Project Manager/Construction Manager',
      defaultPermission: PERMISSIONS.byName.owner,
    },
    constructionManager: {
      value: 'constructionManager',
      label: 'Construction Manager/Engineers',
      defaultPermission: PERMISSIONS.byName.admin,
    },
    designQuality: {
      value: 'designQuality',
      label: 'Design/Quality/Safety/Environmental/Commercial/Planning',
      defaultPermission: PERMISSIONS.byName.admin,
    },
    inspector: {
      value: 'inspector',
      label: 'Inspector/Superintendent',
      defaultPermission: PERMISSIONS.byName.admin,
    },
    clientManager: {
      value: 'clientManager',
      label: 'Client Project Manager',
      defaultPermission: PERMISSIONS.byName.admin,
    },
    supervisor: {
      value: 'supervisor',
      label: 'Works/Shift Supervisor',
      defaultPermission: PERMISSIONS.byName.contributor,
    },
    chargehand: {
      value: 'chargehand',
      label: 'Chargehand/Leading Hand',
      defaultPermission: PERMISSIONS.byName.contributor,
    },
    designer: {
      value: 'designer',
      label: 'Designer',
      defaultPermission: PERMISSIONS.byName.contributor,
    },
    labourer: {
      value: 'labourer',
      label: 'Labourer/Site Operative',
      defaultPermission: PERMISSIONS.byName.viewer,
    },
  },
};
