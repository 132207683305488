import React from 'react';
import classNames from 'classnames';
import { IconButton } from '../../../Button';
import { IconButtonProps } from '../../../Button/IconButton/IconButton';

type FeedEventBaseHeaderActionProps = Omit<IconButtonProps, 'color' | 'variant' | 'size'>;

const FeedEventBaseHeaderAction: React.FC<FeedEventBaseHeaderActionProps> = (props) => (
  <IconButton {...props} color="secondary" variant="text" size="xxs" />
);

type Components = {
  Action: typeof FeedEventBaseHeaderAction;
};

type FeedEventBaseHeaderProps = React.ComponentPropsWithoutRef<'div'>;

export const FeedEventBaseHeader: React.FC<FeedEventBaseHeaderProps> & Components = ({
  children,
  className,
  ...props
}) => {
  return (
    <div {...props} className={classNames('flex justify-between items-center', className)}>
      {children}
    </div>
  );
};

FeedEventBaseHeader.Action = FeedEventBaseHeaderAction;
