import React from 'react';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import classnames from 'classnames';
import { IconButton } from '../../../Button';

type ScrollableArrowProps = {
  width: number;
  position: number;
  parentRef: React.RefObject<HTMLDivElement>;
  isOverflowing: boolean;
};

export const ScrollableLeftArrow = ({
  width,
  position,
  parentRef,
  isOverflowing,
}: ScrollableArrowProps) => {
  const calculateScroll = () => (parentRef.current ? position - width : 0);

  const handleScroll = () => {
    if (!parentRef.current) return;

    parentRef.current.scrollTo({
      left: calculateScroll(),
      behavior: 'smooth',
    });
  };

  const isArrowActive = position > 0;
  if (!isArrowActive || !isOverflowing) return null;

  const style: string = 'absolute left-0 w-8 h-12 mt-0.5 ml-0.5 flex items-center justify-center';
  const gradient: string = 'bg-gradient-to-r from-white via-white';

  return (
    <div className={classnames(style, gradient)}>
      <IconButton
        color="secondary"
        variant="text"
        icon={ChevronLeftIcon}
        onClick={handleScroll}
        size="xxs"
        aria-label="scroll left"
      />
    </div>
  );
};
