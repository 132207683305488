import classnames from 'classnames';
import { InternalProps, THEME } from './Badge.types';

export const iconPropsShaping = ({ theme }: InternalProps): Partial<InternalProps> => {
  const iconColorClassNames = {
    [THEME.BLUE]: 'text-indigo-400',
    [THEME.GRAY_DARK]: 'text-white',
    [THEME.GRAY]: 'text-gray-400',
    [THEME.GREEN_DARK]: 'text-white',
    [THEME.GREEN]: 'text-green-400',
    [THEME.PINK_DARK]: 'text-white',
    [THEME.PINK]: 'text-pink-400',
    [THEME.PURPLE_DARK]: 'text-white',
    [THEME.RED]: 'text-white',
    [THEME.TEAL]: 'text-teal-400',
    [THEME.WHITE]: 'text-gray-400',
    [THEME.YELLOW_ALT]: 'text-white',
    [THEME.YELLOW]: 'text-yellow-400',
  };

  const iconClassNames = classnames('w-4 h-4', iconColorClassNames[theme]);

  return { iconClassNames };
};
