import React from 'react';
import { Link } from 'react-router-dom';
import { Divider } from '@shape-construction/arch-ui';
import ShapeLogo from '../ShapeLogo/ShapeLogo';

const SideDrawerHeader: React.FC = () => (
  <div className="section-header my-2 md:hidden">
    <div className="flex h-16 w-full items-center px-6 py-5">
      <div className="h-full shrink-0">
        <Link to="/">
          <ShapeLogo withLettering />
        </Link>
      </div>
    </div>
    <Divider orientation="horizontal" />
  </div>
);

export { SideDrawerHeader };
