import React, { type ComponentProps, useContext, useRef } from 'react';
import { Input, InputAdornment } from '@material-ui/core';
import { useMessage } from '@messageformat/react';
import { useMatch, useSearchParams } from 'react-router-dom';
import { IconButton } from '@shape-construction/arch-ui';
import { XMarkIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { useDebounceCallback } from '@shape-construction/hooks';
import { SEARCH_STATE } from 'app/constants/SearchState';
import { LayoutContext } from 'app/contexts/Layout.context';
import { BackArrowButton } from './BackArrowButton';
import { useStyles } from './SearchField.style';

const SearchField = () => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams({ q: '' });
  const fieldRef = useRef<HTMLInputElement>();
  const searchQuery = searchParams.get('q') as string;
  const placeholder = useMessage('search.issues.placeholder');
  const {
    layoutConfig: { backButtonRoute, searchState },
  } = useContext(LayoutContext);

  const match = useMatch({ path: '/projects/:projectId', end: false });
  const projectId = match?.params.projectId;

  // track the state of the search field X close button
  const displayClearButton = searchQuery !== '';

  const handleFieldChange: ComponentProps<typeof Input>['onChange'] = useDebounceCallback(
    (event) => {
      searchParams.set('q', event.target.value);
      setSearchParams(searchParams);
    },
    250
  );

  const handleClearSearch = () => {
    if (!fieldRef.current) return;

    searchParams.set('q', '');
    setSearchParams(searchParams);
    fieldRef.current.value = '';
    fieldRef.current.focus();
  };

  if (searchState !== SEARCH_STATE.SEARCHING) return null;

  return (
    <div className="max-w-screen-sm flex-1 mr-1">
      <Input
        inputRef={fieldRef}
        className={classes.root}
        autoFocus
        placeholder={placeholder}
        disableUnderline
        data-cy="search-field"
        onChange={handleFieldChange}
        defaultValue={searchQuery}
        startAdornment={
          <InputAdornment position="start" className="w-6">
            <BackArrowButton route={backButtonRoute} projectId={projectId} />
          </InputAdornment>
        }
        endAdornment={
          displayClearButton && (
            <InputAdornment position="end" className="mr-0.5">
              <IconButton
                color="white"
                icon={XMarkIcon}
                size="xxs"
                variant="text"
                data-testid="clear-icon"
                aria-label="cancel search"
                onClick={handleClearSearch}
              />
            </InputAdornment>
          )
        }
      />
    </div>
  );
};

export { SearchField };
