import React, { useEffect } from 'react';
import { useMessageGetter } from '@messageformat/react';
import { ModalBase } from '@shape-construction/arch-ui/src/ModalBase';
import { useProductTourUpdate } from 'app/queries/productTours/productTours';
import { useProductTourPopover } from '../hooks/useProductTourPopover';

const SCRIPT_ID = 'storylane-demo-script';

type ProductTourStorylaneModalProps = {
  open: boolean;
  onClose: () => void;
  productTourKey: string;
  productTourName: string | JSX.Element;
  storylaneUrl: string;
};

export const ProductTourStorylaneModal: React.FC<ProductTourStorylaneModalProps> = ({
  open,
  onClose,
  productTourKey,
  productTourName,
  storylaneUrl,
}) => {
  const messages = useMessageGetter('productTour.storylaneModal');
  const { openProductTourPopover } = useProductTourPopover();
  const dateNow = new Date().toISOString();

  const { mutate: updateProductTour } = useProductTourUpdate();

  const handleCloseModal = () => {
    onClose();
    openProductTourPopover();

    updateProductTour({
      productTourKey,
      data: {
        finished_at: dateNow,
      },
    });
  };

  useEffect(() => {
    const existingScript = document.getElementById(SCRIPT_ID);

    if (!existingScript) {
      const script = Object.assign(document.createElement('script'), {
        id: SCRIPT_ID,
        src: 'https://js.storylane.io/js/v1/storylane.js',
        type: 'text/javascript',
      });
      script.dataset.testid = SCRIPT_ID;
      document.body.appendChild(script);
    }

    return () => {
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, []);

  return (
    <ModalBase
      open={open}
      onClose={handleCloseModal}
      roundBorders
      outsidePad
      className="h-full w-full"
    >
      <ModalBase.Header onClose={handleCloseModal}>
        <ModalBase.Title>{messages('title', { productTourName })}</ModalBase.Title>
      </ModalBase.Header>

      <ModalBase.Content className="flex p-0">
        <div className="sl-embed relative h-full w-full">
          <iframe
            className="sl-demo absolute inset-0 h-full w-full"
            src={storylaneUrl}
            allow="fullscreen"
            title="Storylane demo"
            aria-label={messages('ariaLabel', { productTourName })}
          />
        </div>
      </ModalBase.Content>
    </ModalBase>
  );
};
