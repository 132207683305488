import React, { useContext } from 'react';
import classNames from 'classnames';

import { NetworkStatusNotification } from 'app/components/NetworkStatusNotification/NetworkStatusNotification';
import { BottomNavigation } from 'app/components/UI/BottomNavigation/BottomNavigation';
import { InstallBar } from 'app/components/UI/InstallBar/InstallBar';
import { Navbar } from 'app/components/UI/Navbar/Navbar';
import { ServiceWorkerLoader } from 'app/components/UI/ServiceWorkerLoader/ServiceWorkerLoader';
import { LayoutContext } from 'app/contexts/Layout.context';
import { EnableNotificationsBanner } from 'app/pages/notifications/EnableNotificationsBanner';
import { useNotifications } from 'app/pages/notifications/hooks/useNotifications';
import { useCurrentUser } from 'app/queries/users/users';

export interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const {
    layoutConfig: { hide, showSideDrawerMenu, showSideDrawer },
  } = useContext(LayoutContext);
  const { userConsent, showEnableNotificationsBanner } = useNotifications();
  const user = useCurrentUser();
  const isUserAuthenticated = !!user;
  const canRenderInstallBar =
    !isUserAuthenticated || !showEnableNotificationsBanner || userConsent !== 'default';

  const Header = (
    <>
      {canRenderInstallBar && <InstallBar isUserAuthenticated={isUserAuthenticated} />}
      {!canRenderInstallBar && <EnableNotificationsBanner />}
      {isUserAuthenticated && !hide && <Navbar />}
      <div
        className={classNames({
          'absolute top-0 w-full z-navigation': !isUserAuthenticated,
        })}
      >
        <NetworkStatusNotification />
      </div>
    </>
  );

  const Body = (
    <>
      <ServiceWorkerLoader showUpdate={isUserAuthenticated} />
      <main
        className={classNames({
          'flex h-full flex-1 flex-col overflow-hidden': isUserAuthenticated,
          'md:flex-row': showSideDrawerMenu || showSideDrawer,
        })}
      >
        {children}
      </main>
    </>
  );
  const Footer = <BottomNavigation className="block md:hidden" />;

  return (
    <div className="relative flex flex-col grow min-w-0 min-h-screen h-full sm:h-screen">
      {Header}
      {Body}
      {Footer}
    </div>
  );
};
