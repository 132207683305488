import { useMessageGetter } from '@messageformat/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  getApiProjectsProjectIdDocuments,
  getGetApiProjectsProjectIdDocumentsInfiniteQueryOptions,
  getGetApiProjectsProjectIdDocumentsQueryKey,
  postApiProjectsProjectIdDocuments,
  useDeleteApiProjectsProjectIdDocumentsDocumentId,
  useGetApiProjectsProjectIdDocumentsDocumentId,
  usePatchApiProjectsProjectIdDocumentsDocumentId,
} from '@shape-construction/api/api';
import type { ProjectSchema } from '@shape-construction/api/model';
import { showErrorToast, showSuccessToast } from '@shape-construction/arch-ui/src/Toast/toasts';
import { useDocumentDirectUpload } from 'app/components/Gallery/hooks/useDocumentDirectUpload';
import useUploadingDocuments from 'app/components/Gallery/hooks/useUploadingDocuments';
import { DocumentPayload } from 'app/components/Gallery/types';
import { fileToUploadingDocument } from 'app/components/Gallery/utils/uploadingDocument';
import { isFileImageType } from 'app/components/Gallery/utils/UtilsGallery';

export const useProjectDocument = useGetApiProjectsProjectIdDocumentsDocumentId;

export const getProjectDocumentsInfiniteQueryOptions: typeof getGetApiProjectsProjectIdDocumentsInfiniteQueryOptions =
  (...args) => {
    const [projectId, params, options] = args;

    return getGetApiProjectsProjectIdDocumentsInfiniteQueryOptions(projectId, params, {
      query: {
        ...options?.query,
        queryFn: ({ pageParam, signal }) =>
          getApiProjectsProjectIdDocuments(
            projectId,
            {
              ...params,
              // @ts-expect-error
              ...pageParam,
            },
            options?.request,
            signal
          ),

        getNextPageParam: (lastPage) => {
          const { hasNextPage, lastEntryCursor } = lastPage.meta;
          if (!hasNextPage) return undefined;

          return { after: lastEntryCursor || undefined };
        },
        initialPageParam: { after: undefined as undefined | string },
      },
    });
  };

export const useDeleteProjectDocument = () => {
  const queryClient = useQueryClient();

  return useDeleteApiProjectsProjectIdDocumentsDocumentId({
    mutation: {
      onSettled: (_, __, { projectId }) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdDocumentsQueryKey(projectId),
        });
      },
    },
  });
};

export const useUpdateProjectDocument = () => {
  const queryClient = useQueryClient();
  return usePatchApiProjectsProjectIdDocumentsDocumentId({
    mutation: {
      onSettled: (_, __, { projectId }) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdDocumentsQueryKey(projectId),
        });
      },
    },
  });
};

type DocumentToUploadPayload = DocumentPayload & { temporaryId: string };

export const useUploadProjectDocument = (projectId: ProjectSchema['id']) => {
  const messages = useMessageGetter('mediaGallery.upload');
  const queryClient = useQueryClient();
  const { addUploadingDocument, removeUploadingDocument } = useUploadingDocuments(projectId);
  const { createDocumentDirectUpload } = useDocumentDirectUpload(projectId);

  return useMutation({
    mutationFn: async (documentToUploadPayload: DocumentToUploadPayload) => {
      const newDocumentId = await createDocumentDirectUpload(
        documentToUploadPayload.temporaryId,
        documentToUploadPayload.file
      );

      return postApiProjectsProjectIdDocuments(projectId, {
        ...documentToUploadPayload.body,
        signed_id: newDocumentId,
      });
    },
    onMutate: (payload) => {
      const uploadingDocument = fileToUploadingDocument(payload.file, {
        temporaryDocumentId: payload.temporaryId,
      });

      const fileType = isFileImageType(payload.file) ? 'image' : 'file';

      if (fileType === 'file') {
        addUploadingDocument({
          ...uploadingDocument,
        });
      }

      if (fileType === 'image') {
        const imageUrl = URL.createObjectURL(payload.file);

        addUploadingDocument({
          ...uploadingDocument,
          imageUrl: {
            original: imageUrl,
            xxl: imageUrl,
            l: imageUrl,
            s: imageUrl,
          },
        });
      }
    },
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: getGetApiProjectsProjectIdDocumentsQueryKey(projectId) })
        .then(() => showSuccessToast({ message: messages('success') }));
    },
    onError: () => {
      showErrorToast({ message: messages('error') });
    },
    onSettled: (_, __, { temporaryId }) => {
      removeUploadingDocument(temporaryId);
    },
  });
};
