import React, { useEffect } from 'react';
import { useMessageGetter } from '@messageformat/react';
import { useQuery } from '@tanstack/react-query';
import { Link, useLocation } from 'react-router-dom';
import type { NotificationsOverviewSchema } from '@shape-construction/api/model';
import { Button, Menu, NotificationBadge, Popover, Portal, cn } from '@shape-construction/arch-ui';
import { BellIcon } from '@shape-construction/arch-ui/src/Icons/outline';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { useFeatureFlag } from 'app/featureFlags/useFeatureFlag';
import { useModal } from 'app/hooks/useModal';
import { MarkAllNotificationRead } from 'app/pages/notifications/components/MarkAllNotificationRead';
import { NotificationList } from 'app/pages/notifications/components/NotificationList';
import { PushNotificationBanner } from 'app/pages/notifications/components/PushNotificationBanner';
import { useNotifications } from 'app/pages/notifications/hooks/useNotifications';
import { usePushNotifications } from 'app/pages/notifications/hooks/usePushNotifications';
import { getNotificationsOverviewQueryOptions } from 'app/queries/notifications/notifications';
import { NavbarButton, navBarButtonClasses } from './NavbarButton';

type NotificationsProps = { distanceToBottomOfScreen?: number };

const popOverGapWithOffset = 24;

export const Notifications = ({ distanceToBottomOfScreen = 0 }: NotificationsProps) => {
  const message = useMessageGetter('notifications');

  const { value: isNotificationsPopoverEnabled } = useFeatureFlag('notifications-popover');

  const { data: notificationOverview } = useQuery(getNotificationsOverviewQueryOptions());
  const totalUnread = notificationOverview
    ? (notificationOverview as NotificationsOverviewSchema).totalUnread
    : 0;

  const { onAskPushNotificationPermission } = usePushNotifications();
  const { showPermissionBanner, showEnableNotificationsBanner } = useNotifications();
  const isPushNotificationBannerOpen = showPermissionBanner && !showEnableNotificationsBanner;

  const { open, openModal, closeModal } = useModal(false);

  const { pathname } = useLocation();
  const isPathnameNotifications = pathname === '/notifications';

  useEffect(() => {
    if (isPathnameNotifications) {
      closeModal();
    }
  }, [isPathnameNotifications, closeModal]);

  const isSmallScreen = useMediaQuery(breakpoints.down('md'));

  const renderTrigger = () => (
    <NotificationBadge
      className="bg-red-400 text-white"
      active={!!totalUnread}
      text={totalUnread ? totalUnread.toString() : ''}
    >
      <BellIcon className="h-6 w-6 text-gray-400" />
    </NotificationBadge>
  );

  const renderContent = () => (
    <>
      <div className="w-full p-4 flex flex-row justify-between items-center flex-none border-b border-neutral-subtlest">
        <span className="text-lg leading-6 font-semibold text-neutral-bold">
          {message('page.title')}
        </span>
        <MarkAllNotificationRead />
      </div>
      <PushNotificationBanner
        open={isPushNotificationBannerOpen}
        onClick={onAskPushNotificationPermission}
      />
      <div className="flex-1 overflow-auto w-full">
        <NotificationList />
      </div>
      <Link
        to="/notifications"
        className="w-full flex flex-row [&>button]:flex-1 [&>button]:text-center [&>button]:flex [&>button]:flex-row [&>button]:justify-center [&>button]:m-4 flex-none"
      >
        <Button color="secondary" size="sm" variant="contained">
          {message('popover.loadMore')}
        </Button>
      </Link>
    </>
  );

  if (!isNotificationsPopoverEnabled) {
    return (
      <Link to="/notifications">
        <NavbarButton aria-label="notifications" className="mr-3 md:mr-6">
          {renderTrigger()}
        </NavbarButton>
      </Link>
    );
  }

  if (isSmallScreen) {
    return (
      <div>
        <Menu.Root>
          <Menu.Trigger as="div">
            <NavbarButton
              aria-label="notification toggle"
              className={cn('mr-2 md:mr-4', { '!bg-gray-700': isPathnameNotifications })}
              disabled={isPathnameNotifications}
            >
              {renderTrigger()}
            </NavbarButton>
          </Menu.Trigger>
          <Portal>
            <Menu.Items
              aria-label="small screen notifications"
              unmount={false}
              className="md:w-62 max-h-[90vh] flex flex-col"
            >
              {renderContent()}
            </Menu.Items>
          </Portal>
        </Menu.Root>
      </div>
    );
  }

  return (
    <Popover.Root open={open}>
      <Popover.Trigger
        onClick={openModal}
        aria-label="notification toggle"
        className={cn('relative flex mr-2 md:mr-4', Object.values(navBarButtonClasses), {
          '!bg-gray-700': isPathnameNotifications,
        })}
        disabled={isPathnameNotifications}
      >
        {renderTrigger()}
      </Popover.Trigger>
      <Popover.Content
        className="max-w-md w-full p-0 [&>*]:!p-0 lg:mr-8 [&>div]:flex [&>div]:flex-col"
        onEscapeKeyDown={closeModal}
        hideArrow
        onClose={closeModal}
        hideCloseIcon
        style={{ maxHeight: distanceToBottomOfScreen - popOverGapWithOffset }}
      >
        {renderContent()}
      </Popover.Content>
    </Popover.Root>
  );
};
