import React, { ElementType, Ref, forwardRef } from 'react';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { Listbox, ListboxButtonProps } from '@headlessui/react';
import { useComposedRefs } from '@radix-ui/react-compose-refs';
import { Slot } from '@radix-ui/react-slot';
import { renderChildren } from '../utils/render';
import { ChevronDownIcon } from '../Icons/outline';
import type { Size, Variant, Color } from './types';
import { useSelectContext } from './SelectContext';
import {
  inputContainerClasses,
  inputContentClasses,
  indicatorClasses,
  adornmentClasses,
} from './input-classes';

type OwnProps = {
  className?: string;
  placeholder?: string;
  size?: Size;
  variant?: Variant;
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  color?: Color;
  showChevronOnHover?: boolean;
};

export type SelectTriggerComponent = {
  displayName: string;
  <TTag extends ElementType>(
    props: SelectTriggerProps<TTag>,
    ref: Ref<HTMLButtonElement>
  ): JSX.Element;
};

export type SelectTriggerProps<TTag extends ElementType> = OwnProps &
  Omit<ListboxButtonProps<TTag>, 'as' | 'color'> & { as?: ElementType };

function SelectTriggerFn<TTag extends ElementType>(
  {
    children,
    className,
    size = 'md',
    variant = 'bordered',
    startAdornment,
    endAdornment,
    placeholder,
    as = 'button',
    color = 'light',
    showChevronOnHover = false,
    ...props
  }: SelectTriggerProps<TTag>,
  forwardedRef: Ref<HTMLButtonElement>
) {
  const { triggerRef, multiple } = useSelectContext();
  const composedRefs = useComposedRefs(forwardedRef, triggerRef);

  return (
    <Listbox.Button
      as={multiple ? 'div' : as}
      role="button"
      ref={composedRefs}
      className={twMerge(
        classNames(
          inputContainerClasses.default,
          inputContainerClasses.variant[variant][color],
          className
        )
      )}
      {...props}
    >
      {(state) => {
        return (
          <>
            {startAdornment && (
              <Slot className={classNames(adornmentClasses.default, adornmentClasses.size[size])}>
                {startAdornment}
              </Slot>
            )}
            <div
              className={classNames(inputContentClasses.default, inputContentClasses.size[size])}
            >
              <div className="flex-1 text-left truncate">{renderChildren(children, state)}</div>
              <ChevronDownIcon
                aria-hidden="true"
                className={classNames(indicatorClasses.default, indicatorClasses.size[size], {
                  'invisible group-hover:visible': showChevronOnHover,
                })}
              />
            </div>
            {endAdornment && (
              <Slot className={classNames(adornmentClasses.default, adornmentClasses.size[size])}>
                {endAdornment}
              </Slot>
            )}
          </>
        );
      }}
    </Listbox.Button>
  );
}

export const SelectTrigger = forwardRef(SelectTriggerFn) as SelectTriggerComponent;
SelectTrigger.displayName = 'Select.Trigger';
