import React, {
  ComponentProps,
  ElementRef,
  createContext,
  forwardRef,
  useContext,
  useRef,
  useState,
  ReactNode,
} from 'react';
import classNames from 'classnames';
import { Menu as MenuPrimitive } from '@headlessui/react';
import { useComposedRefs } from '@radix-ui/react-compose-refs';
import { usePopper } from 'react-popper';
import { twMerge } from 'tailwind-merge';
import { useMediaQuery } from '@shape-construction/hooks';
import { breakpoints } from '../utils/breakpoints';
import { renderChildren } from '../utils/render';
import { DropdownItem } from '../Dropdown/DropdownItem';

const MenuContext = createContext<{
  triggerReference: React.RefObject<React.ElementRef<'button'>>;
}>({
  triggerReference: { current: null },
});

export const MenuRoot: React.FC<ComponentProps<typeof MenuPrimitive>> = (props) => {
  const triggerReference = useRef<React.ElementRef<'button'>>(null);

  return (
    <MenuContext.Provider value={{ triggerReference }}>
      <MenuPrimitive {...props} />
    </MenuContext.Provider>
  );
};
MenuRoot.displayName = 'Menu.Root';

export const MenuTrigger = forwardRef<
  ElementRef<typeof MenuPrimitive.Button>,
  ComponentProps<typeof MenuPrimitive.Button>
>((props, ref) => {
  const { triggerReference } = useContext(MenuContext);
  const composedRefs = useComposedRefs(ref, triggerReference);

  return <MenuPrimitive.Button ref={composedRefs} {...props} />;
});
MenuTrigger.displayName = 'Menu.Trigger';

export const MenuHeading = ({
  children,
  className,
  ...props
}: {
  children?: ReactNode;
  className?: string;
}) => {
  const headingClasses = twMerge(
    'text-xs text-gray-400 leading-4 font-semibold tracking-wider uppercase',
    className
  );

  return (
    <div className="w-full h-8 px-4 pt-3 pb-1">
      <div className={headingClasses} {...props}>
        {children}
      </div>
    </div>
  );
};
MenuHeading.displayName = 'Menu.Heading';

export const MenuItems = forwardRef<
  ElementRef<typeof MenuPrimitive.Items>,
  ComponentProps<typeof MenuPrimitive.Items>
>(({ className, children, ...props }, ref) => {
  const { triggerReference } = useContext(MenuContext);
  const [popperReference, setPopperElement] = useState<HTMLDivElement | null>();
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const composedRefs = useComposedRefs(ref, setPopperElement) as React.Ref<HTMLDivElement>;
  const { styles, attributes } = usePopper(triggerReference.current, popperReference, {
    placement: 'bottom-end',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const popperProperties = isLargeScreen
    ? {
        style: styles.popper,
        ...attributes.popper,
      }
    : {};

  return (
    <MenuPrimitive.Items {...props}>
      {(state) => {
        if (state.open) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'unset';

        return (
          <>
            {/* Menu does not support backdrop natively so, to accomplish that behaviour we are placing a
                  hidden button to work as a backdrop for small layout */}
            <MenuPrimitive.Button
              role="none"
              className={classNames(
                'z-popover fixed inset-0 bg-gray-800 bg-opacity-75 backdrop-filter overflow-none',
                'md:hidden'
              )}
            />
            <div
              ref={composedRefs}
              {...popperProperties}
              className={twMerge(
                classNames(
                  'z-popover flex flex-col items-start py-1 focus:outline-none bg-white shadow-lg ring-1 ring-black ring-opacity-5',
                  'fixed inset-x-0 bottom-0 rounded-t-md',
                  'md:relative md:w-56 md:rounded-md'
                ),
                className
              )}
            >
              {renderChildren(children, state)}
            </div>
          </>
        );
      }}
    </MenuPrimitive.Items>
  );
});
MenuItems.displayName = 'Menu.Items';

export const MenuItem = DropdownItem;
MenuItem.displayName = 'Menu.Item';
