import React from 'react';
import classNames from 'classnames';
import { NotificationBadge } from '@shape-construction/arch-ui';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import UtilsHtml from '../../Utils/UtilsHtml';
import type { MenuItem } from './side-menu.types';

interface SideDrawerIconProps {
  category: MenuItem;
  isActive?: boolean;
}

const SideDrawerIcon: React.FC<SideDrawerIconProps> = ({ category, isActive }) => {
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));

  const notifications = category.notifications;
  const activateBadge = Boolean(isLargeScreen && notifications && notifications > 0);
  const badgeText = activateBadge ? notifications?.toString() : undefined;

  const iconTestId = `res-side-drawer-${UtilsHtml.getIdentifier(category.title)}-icon`;
  const badgeTestId = `res-side-drawer-${UtilsHtml.getIdentifier(category.title)}-lbadge`;

  const iconState = classNames({
    '[&>svg]:text-gray-400': !isActive,
    '[&>svg]:text-gray-800': isActive,
  });

  return (
    <div
      data-testid={iconTestId}
      className="w-[56px] h-[38px] grid pb-px items-center justify-center shrink-0"
    >
      <NotificationBadge
        data-testid={badgeTestId}
        className="bg-red-400 text-white"
        active={activateBadge}
        text={badgeText}
      >
        <span className={iconState}>{category.image}</span>
      </NotificationBadge>
    </div>
  );
};

export { SideDrawerIcon };
