import React from 'react';
import { useMessageGetter } from '@messageformat/react';
import { Alert, Button } from '@shape-construction/arch-ui';
import { PlayCircleIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { useModal } from 'app/hooks/useModal';
import { useProductTourUpdate } from 'app/queries/productTours/productTours';
import { useProductTourPopover } from '../hooks/useProductTourPopover';

interface ProductTourAlertProps {
  openStorylaneModal: () => void;
  productTourKey: string;
  productTourName: string | JSX.Element;
}

export const ProductTourAlert: React.FC<ProductTourAlertProps> = ({
  openStorylaneModal,
  productTourKey,
  productTourName,
}) => {
  const messages = useMessageGetter('productTour.alert');
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const dateNow = new Date().toISOString();

  const { open: isAlertVisible, closeModal: hideAlert } = useModal(true);
  const { openProductTourPopover } = useProductTourPopover();

  const { mutate: updateProductTour } = useProductTourUpdate();

  const handleOpenStorylaneModal = () => {
    hideAlert();
    openStorylaneModal();

    updateProductTour({
      productTourKey,
      data: {
        started_at: dateNow,
      },
    });
  };

  const handleCloseAlert = () => {
    hideAlert();
    openProductTourPopover();

    updateProductTour({
      productTourKey,
      data: {
        started_at: dateNow,
        finished_at: dateNow,
      },
    });
  };

  if (!isAlertVisible) return null;

  return (
    <div className={isLargeScreen ? '' : 'm-2'}>
      <Alert
        color="discovery"
        emphasis="bold"
        rounded={!isLargeScreen}
        customIcon={<PlayCircleIcon className="h-5 w-5 text-white" />}
        onClose={handleCloseAlert}
      >
        <Alert.Message>{messages('message', { productTourName })}</Alert.Message>
        <Alert.Actions>
          <Button variant="outlined" color="white" size="xs" onClick={handleOpenStorylaneModal}>
            {messages('viewTourCTA')}
          </Button>
        </Alert.Actions>
      </Alert>
    </div>
  );
};
