/**
 * Generated by orval v6.21.0 🍺
 * Do not edit manually.
 * Shape API
 * OpenAPI spec version: v1
 */

export type TeamMemberStatusSchema =
  (typeof TeamMemberStatusSchema)[keyof typeof TeamMemberStatusSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TeamMemberStatusSchema = {
  joined: 'joined',
  invited: 'invited',
  archived: 'archived',
} as const;
