import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLastLocation } from 'react-router-dom-last-location';
import { IconButton } from '@shape-construction/arch-ui';
import { ArrowLeftIcon } from '@shape-construction/arch-ui/src/Icons/solid';

export interface BackArrowButtonProps {
  route?: string | (() => void);
  projectId?: string;
}

export const BackArrowButton: React.FC<BackArrowButtonProps> = ({ route, projectId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const appLastLocation = useLastLocation();
  const isSearchUrl = (pathname: string) => pathname.includes('/search');

  const goBack = () => {
    if (typeof route === 'function') {
      route();
      return;
    }
    if (route) {
      navigate(route);
      return;
    }

    if (projectId && isSearchUrl(location.pathname)) {
      navigate(`/projects/${projectId}/issues`);
      return;
    }

    if (!appLastLocation.lastLocation) {
      navigate('/');
      return;
    }

    navigate(-1);
  };

  return (
    <IconButton
      color="white"
      variant="text"
      aria-label="back button"
      onClick={goBack}
      icon={ArrowLeftIcon}
      size="xs"
    />
  );
};
