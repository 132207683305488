import React, { ComponentPropsWithoutRef } from 'react';
import { useDebounceCallback } from '@shape-construction/hooks';
import { twMerge } from 'tailwind-merge';
import { MagnifyingGlassIcon } from '../Icons/outline';

export type SearchFieldProps = Omit<ComponentPropsWithoutRef<'input'>, 'onChange'> & {
  /**
   * Text to render when there's no value
   */
  placeholder?: string;
  /**
   * Callback called when user changes the search value. It is debounced for 300ms
   */
  onChange: (value: string) => void;
};

export const SearchField = React.forwardRef<HTMLInputElement, SearchFieldProps>(
  ({ placeholder = 'Search', onChange, className, ...props }, ref) => {
    const debouncedOnChange = useDebounceCallback(onChange, 250);

    return (
      <div className="flex w-full">
        <div className="relative w-full text-gray-500 focus-within:text-gray-900">
          <div className="pointer-events-none absolute inset-y-0 left-2 flex items-center">
            <MagnifyingGlassIcon className="h-4 w-4" aria-hidden="true" />
          </div>
          <input
            className={twMerge(
              'h-full w-full rounded-md border-0 py-2 pl-9 pr-3 placeholder-gray-500 ring-0 focus:placeholder-gray-400 focus:ring-indigo-500 sm:text-sm',
              className
            )}
            placeholder={placeholder}
            type="search"
            name="searchInput"
            onChange={(event) => debouncedOnChange(event.target.value)}
            ref={ref}
            {...props}
          />
        </div>
      </div>
    );
  }
);
SearchField.displayName = 'SearchField';
