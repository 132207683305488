import React, {
  createRef,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { useElementSize } from '@shape-construction/hooks';
import type { TabsProps } from '../Tabs';
import { ScrollableTab as Tab } from './components/ScrollableTab';
import { ScrollableLeftArrow } from './components/ScrollableLeftArrow';
import { ScrollableRightArrow } from './components/ScrollableRightArrow';

type TabRefs = { [key: string]: React.RefObject<HTMLButtonElement> };

export const ScrollableTabs = ({
  children,
  onChange,
  selectedValue,
}: PropsWithChildren<TabsProps>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const tabsRef = useRef<HTMLDivElement>(null);
  const { width: containerWidth } = useElementSize(containerRef);
  const { width: tabsWidth } = useElementSize(tabsRef);
  const [tabRefs, setTabRefs] = useState<TabRefs>({});
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  /**
   * Track the overflow and scroll position in local state when a window is resized
   */
  useEffect(() => {
    if (!tabsRef.current) return;

    setIsOverflowing(tabsWidth > containerWidth);
    setScrollPosition(tabsRef.current.scrollLeft);
  }, [tabsWidth, containerWidth]);

  /**
   * Track the scroll position in local state when tabs are scrolled manually
   */
  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      setScrollPosition(containerRef.current.scrollLeft);
    };

    const containerElement = containerRef.current;
    containerElement?.addEventListener('scroll', handleScroll);

    return () => {
      containerElement?.removeEventListener('scroll', handleScroll);
    };
  }, [containerRef]);

  /**
   * Assign refs to each tab
   */
  useEffect(() => {
    const childKeys = React.Children.map(children, (_, index) => index) || [];
    setTabRefs((prevTabRefs) => {
      return childKeys.reduce((acc, cur) => {
        if (!prevTabRefs[cur]) {
          acc[cur] = createRef<HTMLButtonElement>();
        } else {
          acc[cur] = prevTabRefs[cur];
        }
        return acc;
      }, {} as TabRefs);
    });
  }, [children]);

  const handleTabChange = (event: React.ChangeEvent, value: number) => {
    if (onChange) {
      onChange(event, value);
    }

    const tabRef = tabRefs[value];
    if (tabRef?.current) {
      tabRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  };

  return (
    <nav className="flex w-full border-b border-gray-200">
      <ScrollableLeftArrow
        width={containerWidth}
        position={scrollPosition}
        parentRef={containerRef}
        isOverflowing={isOverflowing}
      />
      <div
        ref={containerRef}
        className={classNames('flex flex-1 overflow-x-auto overflow-y-hidden', {
          'mx-0.5': isOverflowing,
        })}
      >
        <div
          ref={tabsRef}
          className="-mb-px flex space-x-5 px-4 md:px-8"
          aria-label="Tabs"
          role="tablist"
        >
          {React.Children.map(children, (child, childIndex) => {
            if (!React.isValidElement(child)) return child;

            const childValue = child.props.value === undefined ? childIndex : child.props.value;

            return React.cloneElement(child as ReactElement, {
              ref: tabRefs[childValue],
              value: childValue,
              selected: child.props.selected || childValue === selectedValue,
              onChange: handleTabChange,
            });
          })}
        </div>
      </div>
      <ScrollableRightArrow
        width={containerWidth}
        position={scrollPosition}
        parentRef={containerRef}
        isOverflowing={isOverflowing}
      />
    </nav>
  );
};

ScrollableTabs.Tab = Tab;
