import React from 'react';
import { XMarkIcon } from '../../Icons/solid';
import { IconButton } from '../../Button/IconButton/IconButton';

import { AlertColor, AlertEmphasis } from '../alert-config';

export type AlertCloseButtonProps = {
  onClose: () => void;
  color: AlertColor;
  emphasis: AlertEmphasis;
};

export const AlertCloseButton: React.FC<AlertCloseButtonProps> = ({ onClose, color, emphasis }) => {
  const iconVariant = emphasis === 'subtle' ? 'text' : 'contained';
  return (
    <IconButton
      aria-label="close alert"
      type="button"
      size="xl"
      color={color}
      variant={iconVariant}
      icon={XMarkIcon}
      onClick={onClose}
    />
  );
};
