import React from 'react';
import classNames from 'classnames';
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import { Tooltip } from '@shape-construction/arch-ui';
import UtilsHtml from '../../Utils/UtilsHtml';
import type { MenuItem } from './side-menu.types';
import { SideDrawerIcon } from './SideDrawerIcon';

interface SideDrawerListItemProps {
  category: MenuItem;
  isExpanded: boolean;
  onClick?: React.MouseEventHandler;
}

const SideDrawerListItem: React.FC<SideDrawerListItemProps> = React.memo(
  ({ category, isExpanded, onClick }) => {
    const location = useLocation();
    const matchRoute = matchPath(category.isActiveOn || category.route, location.pathname);
    const isRoute = Boolean(matchRoute);
    const linkTestId = `res-side-drawer-${UtilsHtml.getIdentifier(category.title)}-link`;
    const textTestId = `res-side-drawer-${UtilsHtml.getIdentifier(category.title)}-text`;
    const isDisabled = Boolean(category.disabled);

    const handleRedirect: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
      if (isDisabled) {
        e.preventDefault();
        return;
      }
      if (onClick) onClick(e);
    };

    return (
      <NavLink
        data-testid={linkTestId}
        to={category.route}
        className={classNames('flex w-full', {
          'pointer-events-none': isDisabled,
        })}
        onClick={handleRedirect}
      >
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <div
              className={classNames(
                'w-full px-0 rounded hover:bg-gray-100 flex items-center text-left gap-3 flex-nowrap h-[38px] my-px',
                {
                  'bg-gray-200': isRoute,
                }
              )}
            >
              <SideDrawerIcon category={category} isActive={isRoute} />
              {isExpanded && (
                <span
                  className="text-gray-800 min-w-[150px] font-medium text-sm"
                  data-testid={textTestId}
                >
                  {category.title}
                </span>
              )}
            </div>
          </Tooltip.Trigger>
          <Tooltip.Content side="right" hidden={isExpanded}>
            {category.title}
          </Tooltip.Content>
        </Tooltip.Root>
      </NavLink>
    );
  }
);

export { SideDrawerListItem };
