import React from 'react';
import classNames from 'classnames';
import { getChildByType } from 'react-nanny';
import { twMerge } from 'tailwind-merge';
import { CardFooter } from './components/CardFooter';
import { CardHeader } from './components/CardHeader';
import { CardBody } from './components/CardBody';
import { CardSize } from './Card.types';

type Components = {
  Header: typeof CardHeader;
  Body: typeof CardBody;
  Footer: typeof CardFooter;
};

export type CardProps = {
  children: React.ReactNode;
  size: CardSize;
  className?: string;
};

export const Card: React.FC<CardProps> & Components = ({ className, children, size, ...props }) => {
  const cardHeader = getChildByType(children, [CardHeader]) as JSX.Element;
  const cardBody = getChildByType(children, [CardBody]) as JSX.Element;
  const cardFooter = getChildByType(children, [CardFooter]) as JSX.Element;

  const sizedHeader = cardHeader
    ? React.cloneElement(cardHeader, { ...cardHeader.props, size }, cardHeader.props.children)
    : null;

  const sizedFooter = cardFooter
    ? React.cloneElement(cardFooter, { ...cardFooter.props, size }, cardFooter.props.children)
    : null;

  return (
    <div
      className={twMerge(
        classNames('bg-white shadow flex flex-col border-gray-200 border-2 rounded-md', className)
      )}
      {...props}
    >
      {sizedHeader}
      {cardBody}
      {sizedFooter}
    </div>
  );
};

Card.Header = CardHeader;
Card.Body = CardBody;
Card.Footer = CardFooter;
