import React from 'react';
import { useModal } from 'app/hooks/useModal';
import { useProductTour } from 'app/queries/productTours/productTours';
import { useCurrentProductPage } from './hooks/useCurrentProductPage';
import { ProductTourAlert } from './ProductTourAlert/ProductTourAlert';
import { ProductTourStorylaneModal } from './ProductTourStorylaneModal/ProductTourStorylaneModal';

export const ProductTour: React.FC = () => {
  const { open, openModal, closeModal } = useModal(false);

  const currentProductPage = useCurrentProductPage();
  const productTourKey = currentProductPage?.key;

  const { data } = useProductTour(productTourKey!, {
    query: { enabled: Boolean(productTourKey) },
  });

  if (!productTourKey || !data || data.firstStartedAt) return null;

  const productTourName = currentProductPage?.title || data.name;

  return (
    <>
      <ProductTourAlert
        openStorylaneModal={openModal}
        productTourKey={productTourKey}
        productTourName={productTourName}
      />
      <ProductTourStorylaneModal
        open={open}
        onClose={closeModal}
        productTourKey={productTourKey}
        productTourName={productTourName}
        storylaneUrl={data.storylaneUrl}
      />
    </>
  );
};
