import React, { useRef, useState } from 'react';
import { useMessageGetter } from '@messageformat/react';
import classNames from 'classnames';
import type { ProjectSchema } from '@shape-construction/api/model';
import { FileExtensionIcon } from '@shape-construction/arch-ui';
import { ModalBase as Modal } from '@shape-construction/arch-ui/src/ModalBase';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { DocumentMetadata } from './components/DocumentMetadata/DocumentMetadata';
import { UploadSave } from './components/UploadSave/UploadSave';
import { uploadModalSize } from './constants';
import type { DocumentBody, DocumentPreview } from './types';
import { getFileExtension, isFileImageType } from './utils/UtilsGallery';

type UploadPreviewProps = {
  projectId: ProjectSchema['id'];
  documentPreview: DocumentPreview;
  onUploadDocument: (file: File, body: DocumentBody) => void;
  onClosePreview: () => void;
};

export const UploadPreview = ({
  projectId,
  documentPreview,
  onUploadDocument,
  onClosePreview,
}: UploadPreviewProps) => {
  const messages = useMessageGetter('projectGallery');
  const initialFocusRef = useRef(null);
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const contentHeight = isLargeScreen ? 'md:h-[400px]' : 'max-h-[66vh] h-full';

  const initialBody: DocumentBody = {
    locationId: null,
    caption: null,
  };
  const [documentBody, setDocumentBody] = useState<DocumentBody>(initialBody);

  if (!documentPreview) return null;

  const onSaveDocumentBody = (field: DocumentBody) => {
    setDocumentBody({ ...field });
  };

  const onUpload = () => {
    return onUploadDocument(documentPreview.fileToUpload, documentBody);
  };

  const renderPreview = () => {
    return isFileImageType(documentPreview.fileToUpload) ? renderImage() : renderFile();
  };

  const renderImage = () => {
    return (
      <img
        className={classNames('object-contain', contentHeight)}
        src={documentPreview.preview}
        alt={documentPreview.fileToUpload.name}
      />
    );
  };

  const renderFile = () => {
    return (
      <div className={classNames('flex mx-auto place-items-center', contentHeight)}>
        <div className="flex flex-col">
          <FileExtensionIcon
            extension={getFileExtension(documentPreview.fileToUpload)}
            className="mx-auto"
            width="80"
            height="80"
          />
          <div className="mx-auto mt-4 text-sm leading-5 font-medium">
            {documentPreview.fileToUpload.name}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open
      fullScreen={!isLargeScreen}
      outsidePad={false}
      className={uploadModalSize}
      roundBorders={isLargeScreen}
      onClose={onClosePreview}
    >
      <Modal.Header onClose={onClosePreview} className="text-lg leading-7 font-medium">
        {messages('upload.new')}
      </Modal.Header>
      <Modal.Content>
        {renderPreview()}
        <div ref={initialFocusRef} tabIndex={-1} />
      </Modal.Content>
      <Modal.Footer>
        <div className="flex flex-col mx-auto mt-2 gap-y-4 w-full">
          <DocumentMetadata projectId={projectId} handleSave={onSaveDocumentBody} />
          <div className="flex justify-end">
            <UploadSave onHandleSave={onUpload} />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
