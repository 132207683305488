import React, { useMemo } from 'react';
import { getChildByTypeDeep } from 'react-nanny';
import { Link } from '../Link/Link';
import { PrimaryAction } from './PrimaryAction';
import { SecondaryAction } from './SecondaryAction';

type Components = {
  PrimaryAction: typeof PrimaryAction;
  SecondaryAction: typeof SecondaryAction;
  Link: typeof Link;
};

export type EmptyStateProps = {
  icon?: React.ReactNode;
  title?: string;
  body?: string | React.ReactNode;
  children?: React.ReactNode;
};

export const EmptyState: React.FC<EmptyStateProps> & Components = ({
  icon,
  title,
  body,
  children,
}) => {
  const { primaryAction, secondaryAction, link } = useMemo(
    () => ({
      primaryAction: getChildByTypeDeep(children, EmptyState.PrimaryAction),
      secondaryAction: getChildByTypeDeep(children, EmptyState.SecondaryAction),
      link: getChildByTypeDeep(children, EmptyState.Link),
    }),
    [children]
  );

  return (
    <div className="flex flex-col items-center px-4 pt-6 pb-12 md:px-6 md:pt-10 md:pb-20">
      {icon && <div className="mb-2 lg:mb-4 text-gray-400">{icon}</div>}
      <h3 className="text-sm leading-5 font-medium text-gray-800 text-center">{title}</h3>
      {body && (
        <div className="text-sm leading-5 font-normal text-gray-500 mt-1 text-center">{body}</div>
      )}
      {(primaryAction || secondaryAction) && (
        <div className="mt-6 flex gap-x-2">
          {primaryAction}
          {secondaryAction}
        </div>
      )}
      {link && <div className="mt-6 ">{link}</div>}
    </div>
  );
};

EmptyState.PrimaryAction = PrimaryAction;
EmptyState.SecondaryAction = SecondaryAction;
EmptyState.Link = Link;
