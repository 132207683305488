import React from 'react';
import { useMessageGetter } from '@messageformat/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import type { NotificationTypeSchema, NotificationSchema } from '@shape-construction/api/model';
import { EmptyState } from '@shape-construction/arch-ui';
import { BellIcon } from '@shape-construction/arch-ui/src/Icons/outline';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { InfiniteLoadWaypoints } from 'app/components/InfiniteLoadWaypoints/InfiniteLoadWaypoints';
import { getNotificationsInfiniteQueryOptions } from 'app/queries/notifications/notifications';
import { NotificationItem } from './NotificationItem';
import { NotificationSkeleton } from './NotificationSkeleton';

const isSequentialComment = (
  previousNotification: NotificationSchema,
  nextNotification?: NotificationSchema
) => {
  if (!nextNotification) return false;

  // issue comment
  const issueCommentType: NotificationTypeSchema[] = [
    'new_issue_comment',
    'new_issue_private_comment',
  ];
  if (issueCommentType.includes(previousNotification.type)) {
    return (
      previousNotification.type === nextNotification.type &&
      previousNotification.params?.issueId === nextNotification.params?.issueId &&
      previousNotification.read === nextNotification.read
    );
  }

  // shift report comment
  const shiftReportCommentType: NotificationTypeSchema[] = ['new_shift_report_comment'];
  if (shiftReportCommentType.includes(previousNotification.type)) {
    return (
      previousNotification.type === nextNotification.type &&
      previousNotification.params?.shiftReportId === nextNotification.params?.shiftReportId &&
      previousNotification.params?.channel === nextNotification.params?.channel &&
      previousNotification?.read === nextNotification?.read
    );
  }

  return false;
};

export type NotificationListProps = StateFullComponent<
  React.HtmlHTMLAttributes<HTMLDivElement> & { isInfinite?: boolean },
  typeof NotificationSkeleton,
  null,
  null
>;

const NotificationList: NotificationListProps = (props) => {
  const messages = useMessageGetter('notifications');
  const isSmallScreen = useMediaQuery(breakpoints.down('md'));
  const scrollableAncestor = isSmallScreen ? window : undefined;

  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useInfiniteQuery(
    getNotificationsInfiniteQueryOptions()
  );

  const flatNotifications = data?.pages.flatMap(({ notifications }) => notifications);
  const isEmpty = flatNotifications?.length === 0;

  if (isEmpty) {
    return (
      <EmptyState
        icon={<BellIcon className="h-12 w-12 lg:w-14 lg:h-14" />}
        title={messages('emptyState.title')}
      />
    );
  }

  const renderContent = () => {
    return flatNotifications?.map((notification, index) => {
      const isLastEvent = index === flatNotifications.length - 1;
      const skipNotification =
        index > 0 && isSequentialComment(flatNotifications[index - 1], notification);

      if (props.isInfinite) {
        return (
          <React.Fragment key={notification.id}>
            <InfiniteLoadWaypoints
              scrollableAncestor={scrollableAncestor}
              hasNextPage={hasNextPage}
              fetchNext={fetchNextPage}
              isLast={isLastEvent}
            >
              {skipNotification ? null : (
                <NotificationItem
                  batchNotification={isSequentialComment(
                    notification,
                    flatNotifications[index + 1]
                  )}
                  {...notification}
                />
              )}
            </InfiniteLoadWaypoints>
          </React.Fragment>
        );
      }

      return skipNotification ? null : (
        <NotificationItem
          key={notification.id}
          batchNotification={isSequentialComment(notification, flatNotifications[index + 1])}
          {...notification}
        />
      );
    });
  };

  return (
    <div className={classNames('h-full', props.className)}>
      {renderContent()}
      {isLoading || isFetchingNextPage ? <NotificationSkeleton length={10} /> : null}
    </div>
  );
};

NotificationList.Loading = NotificationSkeleton;

export { NotificationList };
