import type { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

export let instance: AxiosInstance;

export const initialiseAxiosClient = (newInstance: AxiosInstance) => {
  instance = newInstance;

  return instance;
};

export const customInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const axiosConfig: AxiosRequestConfig = { ...config, ...options };
  return instance(axiosConfig).then(({ data }) => data);
};

export interface ErrorType<Error> extends AxiosError<Error> {}
