import React from 'react';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

const Tooltip: React.FC<TooltipPrimitive.TooltipProps> = ({ children, ...props }) => (
  <TooltipPrimitive.Provider>
    <TooltipPrimitive.Root {...props}>{children}</TooltipPrimitive.Root>
  </TooltipPrimitive.Provider>
);
const TooltipTrigger = TooltipPrimitive.Trigger;
TooltipTrigger.displayName = 'Tooltip.Trigger';

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, children, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={twMerge(
      classNames(
        'data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade',
        'data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade',
        'data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade',
        'data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade',
        'will-change-[transform,opacity]',
        'select-none rounded-md border px-2 py-1',
        'bg-gray-800 border-gray-700 border-opacity-75 shadow-md w-full max-w-xs',
        'text-xs leading-4 font-normal text-white [text-wrap:wrap]',
        className
      )
    )}
    {...props}
  >
    {children}
    <TooltipPrimitive.Arrow />
  </TooltipPrimitive.Content>
));
TooltipContent.displayName = 'Tooltip.Content';

export { Tooltip, TooltipTrigger, TooltipContent };
