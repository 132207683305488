import { Path } from 'react-hook-form';
import type {
  IssueStateSchema,
  IssueGroupSchema,
  GetApiProjectsProjectIdIssuesGroupCountParams,
} from '@shape-construction/api/model';
import type { ImpactType } from 'app/constants/Impact';
import type { TabOption } from './issue-tab-options';

export type StatusFields = ImpactType | IssueStateSchema | 'default';
type AvailableOptions =
  | 'impact'
  | 'state'
  | 'observer'
  | 'observer_team'
  | 'responsible'
  | 'responsible_team'
  | 'location'
  | 'discipline'
  | 'custom_field';

export type IssueGroupOptions = Extract<IssueGroupSchema, AvailableOptions>;
export type GroupingMenuOption = {
  apiKey: IssueGroupOptions;
  label?: string;
  key: IssueGroupOptions;
  name: string;
  parameters?: GetApiProjectsProjectIdIssuesGroupCountParams;
  enabled: boolean;
  customOrdering?: StatusFields[];
  customNaming?: (groupLabel?: string | undefined) => Partial<Record<StatusFields, string>>;
  options?: GroupingSubOption<IssueGroupOptions>[];
};

export type GroupingSubOption<T extends IssueGroupOptions> = Omit<
  GroupingMenuOption,
  'key' | 'enabled' | 'options'
> & {
  key: `${T}.options.${number}`;
};

export type GroupingMenu = Partial<Record<IssueGroupOptions, GroupingMenuOption>>;
export type GroupingMenuKeys = Path<GroupingMenu>;

export type CurrentGrouping = {
  groupingItems: GroupingMenuKeys[];
  setGroupingItems: (groups: GroupingMenuKeys[]) => void;
};

export const defaultCollapsed: Record<TabOption, IssueStateSchema[]> = {
  myIssues: [],
  drafts: [],
  all: [],
  archived: [],
};

export const defaultTabGrouping: Record<TabOption, GroupingMenuKeys[]> = {
  drafts: [],
  all: ['state'],
  archived: [],
  myIssues: ['state'],
};
