import { useQueryClient } from '@tanstack/react-query';
import {
  getGetApiProjectsProjectIdIssueViewsQueryKey,
  useGetApiProjectsProjectIdIssueViews,
  usePatchApiProjectsProjectIdIssueViewsIssueViewId,
  usePostApiProjectsProjectIdIssueViews,
} from '@shape-construction/api/api';

export const useProjectIssueViews = useGetApiProjectsProjectIdIssueViews;

export const useCreateProjectIssueView = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdIssueViews({
    mutation: {
      onSuccess: (_, { projectId }) =>
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdIssueViewsQueryKey(projectId),
        }),
    },
  });
};

export const useUpdateProjectIssueView = () => {
  const queryClient = useQueryClient();

  return usePatchApiProjectsProjectIdIssueViewsIssueViewId({
    mutation: {
      onSuccess: (_, { projectId }) =>
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdIssueViewsQueryKey(projectId),
        }),
    },
  });
};
