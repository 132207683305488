import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { environment } from '../config/environment';
import { persistStore, storePersistReducer } from '../lib/persist';
import * as actionTypes from './actions/actionTypes';
import errorsReducer from './errors/errorSlice';
import installDialogReducer from './install-dialog/installDialogSlice';
import { issuesReducer } from './issues/issues-reducer';
import { notificationsReducer } from './notifications/notifications-reducer';
import printPreferencesReducer from './print-preferences/printPreferencesSlice';
import { layoutReducer } from './reducers/layout';
import timelineTypesFilterReducer from './timeline-events/timeline-types-filtering/timeline-types-filtering';

const appReducer = combineReducers({
  errors: errorsReducer,
  issues: issuesReducer,
  printPreferences: printPreferencesReducer,
  layout: layoutReducer,
  notifications: notificationsReducer,
  installDialog: installDialogReducer,
  timelineTypesFilter: timelineTypesFilterReducer,
});

export const rootReducer = (state: any, action: any) => {
  let returnedState = state;
  // Reset Redux state to prevent persisted data in
  // local storage being available to unauthenticated users
  if ([actionTypes.RESET_STATE].includes(action.type)) {
    // Ensures the auth persistor is not cleared upon logout
    // Any other nested persistors should be included below
    const { messages } = state;
    returnedState = { messages };
  }

  return appReducer(returnedState, action);
};

export const createReduxStore = (preloadedState = {}) => {
  const store = configureStore({
    devTools: process.env.NODE_ENV === 'development' || environment.REDUX_DEVTOOLS_ENABLED,
    preloadedState,
    reducer: storePersistReducer(rootReducer),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // TODO: this should be enable in future to prevent mutability and unserialize data in our store
        serializableCheck: false,
        immutableCheck: false,
      }),
  });

  // Used in ReduxApp for PersistGate
  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
};

const { store: defaultStore, persistor: defaultPersistor } = createReduxStore();

export const store = defaultStore;
export const persistor = defaultPersistor;

export type Store = typeof store;
export type RootState = ReturnType<typeof appReducer>;
