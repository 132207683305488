import React from 'react';
import { useMessageGetter } from '@messageformat/react';
import { Link, useLocation } from 'react-router-dom';
import { ProjectSchema } from '@shape-construction/api/model';
import { Menu } from '@shape-construction/arch-ui';
import {
  ShapeShiftReportIcon,
  WrenchScrewdriverIcon,
} from '@shape-construction/arch-ui/src/Icons/outline';
import { MenuHeading } from '@shape-construction/arch-ui/src/Menu/Menu';
import { useCreateDraftReport } from '../../hooks/useCreateDraftReport';
import { useProject } from '../../queries/projects/projects';

export type ShiftManagerSectionProps = {
  projectId: ProjectSchema['id'];
};

export const ShiftManagerSection = ({ projectId }: ShiftManagerSectionProps) => {
  const messages = useMessageGetter('quickNewButton');
  const location = useLocation();
  const { data: project } = useProject(projectId);
  const canCreateShiftReport = project?.availableActions?.createShiftReport;
  const canCreateShiftActivity = project?.availableActions?.createWeeklyWorkPlan; // api is not returning the correct permission y
  const canCreateShiftReportOrActivity = canCreateShiftReport || canCreateShiftActivity;
  const createDraftReport = useCreateDraftReport(projectId);
  const activityLink = {
    to: `/projects/${projectId}/shift-reports/activities/new`,
    state: { background: location },
  };

  if (!project || !canCreateShiftReportOrActivity) return null;

  return (
    <>
      <MenuHeading>{messages('shiftManager.heading')}</MenuHeading>
      {canCreateShiftReport && (
        <Menu.Item
          aria-label={messages('shiftManager.options.shiftReport.title')}
          onClick={() => createDraftReport()}
        >
          <div className="flex flex-row gap-3">
            <div className="justify-start items-start gap-2.5 flex">
              <ShapeShiftReportIcon className="opacity-50 w-5 h-5" />
            </div>
            <div className="flex flex-col gap-1 justify-start items-start">
              <div className="text-sm font-medium leading-tight">
                {messages('shiftManager.options.shiftReport.title')}
              </div>
            </div>
          </div>
        </Menu.Item>
      )}
      {canCreateShiftActivity && (
        <Link
          {...activityLink}
          key={messages('shiftManager.options.activity.title')}
          className="w-full"
        >
          <Menu.Item aria-label={messages('shiftManager.options.activity.title')}>
            <div className="flex flex-row gap-3">
              <div className="justify-start items-start gap-2.5 flex">
                <WrenchScrewdriverIcon className="opacity-50 w-5 h-5" />
              </div>
              <div className="flex flex-col gap-1 justify-start items-start">
                <div className="text-sm font-medium leading-tight">
                  {messages('shiftManager.options.activity.title')}
                </div>
              </div>
            </div>
          </Menu.Item>
        </Link>
      )}
    </>
  );
};
