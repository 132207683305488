import { atom, useAtom } from 'jotai';

export const openProductTourPopoverAtom = atom<boolean>(false);

export const useProductTourPopover = () => {
  const [isProductTourPopoverOpen, setProductTourPopoverOpen] = useAtom(openProductTourPopoverAtom);

  const openProductTourPopover = () => setProductTourPopoverOpen(true);
  const closeProductTourPopover = () => setProductTourPopoverOpen(false);

  return { isProductTourPopoverOpen, openProductTourPopover, closeProductTourPopover };
};
