import { useDispatch, useSelector } from 'react-redux';
import {
  closeInstallDialog as closeInstallDialogAction,
  openInstallDialog as openInstallDialogAction,
} from '../store/install-dialog/installDialogSlice';
import { useUserAgent } from './useUserAgent';

// the prompt is what we must call for making the browsers show the famous dialog
let installPrompt: any = null;

window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  installPrompt = e;
});

function clearInstallPrompt() {
  installPrompt = null;
}

export function useInstall() {
  const dispatch = useDispatch();
  const { safari, touchDevice } = useUserAgent();
  const installDialogIsOpen = useSelector((state: any) => state.installDialog).isOpen;
  const isSafariOnNonDesktop = safari && touchDevice;

  const isInStandaloneMode =
    ('standalone' in window.navigator && window.navigator.standalone) ||
    (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches);

  const openInstallDialog = () => {
    dispatch(openInstallDialogAction());
  };
  const closeInstallDialog = () => {
    dispatch(closeInstallDialogAction());
  };

  if (isSafariOnNonDesktop) {
    installPrompt = {
      prompt: openInstallDialog,
    };
  }

  const retVal = {
    clearInstallPrompt,
    closeInstallDialog,
    installDialogIsOpen,
    installPrompt,
    isInstallSupported: typeof installPrompt?.prompt === 'function',
    isInStandaloneMode,
    isSafariOnNonDesktop,
    openInstallDialog,
  };

  return retVal;
}
