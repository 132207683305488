import { useQueryClient } from '@tanstack/react-query';
import {
  useGetApiProjects,
  useGetApiProjectsProjectId,
  usePostApiProjects,
  usePostApiProjectsProjectIdArchive,
  usePostApiProjectsProjectIdTeamsTeamIdMembers,
  usePostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail,
  usePostApiProjectsProjectIdTeamsTeamIdResendMembersInvites,
  usePostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive,
  usePostApiProjectsProjectIdDefault,
  useDeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId,
  getGetApiProjectsQueryKey,
  getGetApiProjectsProjectIdPeopleQueryKey,
  getGetApiUsersMeQueryKey,
  getGetApiProjectsProjectIdQueryOptions,
  getGetApiProjectsQueryOptions,
} from '@shape-construction/api/api';

// queries
/**
 * @deprecated use getProjectsQueryOptions instead
 */
export const useProjects = useGetApiProjects;
export const getProjectsQueryOptions = getGetApiProjectsQueryOptions;

/**
 * @deprecated use getProjectQueryOptions instead
 */
export const useProject = useGetApiProjectsProjectId;
export const getProjectQueryOptions = getGetApiProjectsProjectIdQueryOptions;

// mutations
export const useProjectCreate: typeof usePostApiProjects = (options) => {
  const queryClient = useQueryClient();

  return usePostApiProjects({
    ...options,
    mutation: {
      ...options?.mutation,
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: getGetApiProjectsQueryKey() });
      },
    },
  });
};

export const useProjectArchive: typeof usePostApiProjectsProjectIdArchive = (options) => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdArchive({
    ...options,
    mutation: {
      ...options?.mutation,
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: getGetApiProjectsQueryKey() });
      },
    },
  });
};
export const useProjectInvite: typeof usePostApiProjectsProjectIdTeamsTeamIdMembers = (options) => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdTeamsTeamIdMembers({
    ...options,
    mutation: {
      onSettled: (_, __, { projectId }) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdPeopleQueryKey(projectId),
        });
      },
    },
  });
};

export const useProjectResendInviteEmail =
  usePostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail;

export const useProjectResendAllPendingMembersInviteEmail =
  usePostApiProjectsProjectIdTeamsTeamIdResendMembersInvites;

export const useProjectDeleteInvite: typeof useDeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId =
  (options) => {
    const queryClient = useQueryClient();
    return useDeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId({
      ...options,
      mutation: {
        ...options?.mutation,
        onSettled: (_, __, { projectId }) => {
          queryClient.invalidateQueries({
            queryKey: getGetApiProjectsProjectIdPeopleQueryKey(projectId),
          });
        },
      },
    });
  };

export const useProjectArchiveTeamMember: typeof usePostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive =
  (options) => {
    const queryClient = useQueryClient();

    return usePostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive({
      ...options,
      mutation: {
        ...options?.mutation,
        onSettled: (_, __, { projectId }) => {
          queryClient.invalidateQueries({
            queryKey: getGetApiProjectsProjectIdPeopleQueryKey(projectId),
          });
        },
      },
    });
  };

export const useProjectDefaultMutation: typeof usePostApiProjectsProjectIdDefault = (options) => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdDefault({
    ...options,
    mutation: {
      ...options?.mutation,
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: getGetApiUsersMeQueryKey() });
      },
    },
  });
};

// selectors
export const getJoinedProjectsQueryOptions = (
  ...args: Parameters<typeof getProjectsQueryOptions>
) => {
  const [options] = args;
  return getProjectsQueryOptions({
    query: {
      ...options?.query,
      select: (data) =>
        data.filter(
          ({ currentTeamMemberStatus, archived }) =>
            currentTeamMemberStatus === 'joined' && !archived
        ),
    },
  });
};

export const getProjectTeamMemberRoleQueryOptions = (
  ...args: Parameters<typeof getProjectQueryOptions>
) => {
  const [projectId, options] = args;
  return getProjectQueryOptions(projectId, {
    query: {
      ...options?.query,
      select: (project) => project.currentTeamMemberRole || 'viewer',
    },
  });
};

export const useArchivedProjects = () =>
  useProjects({
    query: {
      select: (data) =>
        data.filter(
          ({ archived, currentTeamMemberStatus }) =>
            archived && currentTeamMemberStatus === 'joined'
        ),
    },
  });
