import React, { ComponentProps } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AuthorSelect } from './AuthorSelect/AuthorSelect';
import { DateSelect } from './DateSelect/DateSelect';
import { FormatSelect } from './FormatSelect/FormatSelect';
import type { GalleryFilterFormValues } from './GalleryFilterForm';
import { LocationsSelect } from './LocationsSelect/LocationsSelect';
import { SortOrderSelect } from './SortOrderSelect';

export const GalleryFilterFormDate = (props: ComponentProps<typeof DateSelect>) => {
  const { control } = useFormContext<GalleryFilterFormValues>();
  return (
    <Controller
      name="date"
      control={control}
      render={({ field }) => <DateSelect {...props} {...field} />}
    />
  );
};

export const GalleryFilterFormFormat = (props: ComponentProps<typeof FormatSelect>) => {
  const { control } = useFormContext<GalleryFilterFormValues>();
  return (
    <Controller
      name="format"
      control={control}
      render={({ field }) => <FormatSelect {...props} {...field} />}
    />
  );
};

export const GalleryFilterFormLocations = (props: ComponentProps<typeof LocationsSelect>) => {
  const { control } = useFormContext<GalleryFilterFormValues>();
  return (
    <Controller
      name="locations"
      control={control}
      render={({ field }) => <LocationsSelect {...props} {...field} />}
    />
  );
};

export const GalleryFilterFormAuthors = (props: ComponentProps<typeof AuthorSelect>) => {
  const { control } = useFormContext<GalleryFilterFormValues>();
  return (
    <Controller
      name="authors"
      control={control}
      render={({ field }) => <AuthorSelect {...props} {...field} />}
    />
  );
};

export const GallerySortOrderSelect = (props: ComponentProps<typeof SortOrderSelect>) => {
  const { control } = useFormContext<GalleryFilterFormValues>();
  return (
    <Controller
      name="order"
      control={control}
      render={({ field }) => <SortOrderSelect {...props} {...field} />}
    />
  );
};
