import React, { useState } from 'react';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { ExclamationCircleIcon, EyeIcon, EyeSlashIcon } from '../Icons/solid';
import { InputBaseProps } from '../types/InputBase';
import { IconButton } from '../Button/IconButton/IconButton';

export interface InputPasswordProps extends InputBaseProps {
  /**
   * Determines if it should show toggle to show/hide input value.
   */
  showVisibilityToggle?: boolean;
}

export const InputPassword = React.forwardRef<HTMLInputElement, InputPasswordProps>(
  (
    {
      autoFocus = false,
      cornerAdornment,
      disabled,
      error,
      fullWidth,
      touched = true,
      id,
      label,
      name,
      onBlur,
      onChange,
      required,
      value,
      showVisibilityToggle,
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const ToggleIcon = showPassword ? EyeSlashIcon : EyeIcon;
    const showError = error && touched;

    const errorClassnames = classNames(
      'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3',
      {
        'right-8': showVisibilityToggle,
      }
    );

    const labelClassnames = classNames('block text-sm font-medium text-gray-700', {
      'w-full': fullWidth,
    });

    const inputClassnames = twMerge(
      classNames(
        'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none border-gray-300 placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm',
        {
          'pr-10': showVisibilityToggle && !showError,
          'pr-10 border-red-300 text-red-800 placeholder-red-300 focus:ring-red-400 focus:border-red-400':
            showError,
          'pr-[72px]': showVisibilityToggle && showError,
          'opacity-50': disabled,
        }
      )
    );

    return (
      <label htmlFor={id} className={labelClassnames}>
        <div className="flex justify-between">
          <div className="flex gap-x-1">
            {label}
            {required && <div className="text-red-600">*</div>}
          </div>
          {cornerAdornment && <div>{cornerAdornment}</div>}
        </div>
        <div className="relative mt-1">
          <input
            ref={ref}
            autoFocus={autoFocus}
            className={inputClassnames}
            disabled={disabled}
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            required={required}
            value={value}
            type={showPassword ? 'text' : 'password'}
          />
          {showVisibilityToggle && (
            <div className="absolute inset-y-px right-px bg-gray-50 border-0 border-l border-l-gray-300 rounded-r-md flex items-center">
              <IconButton
                role="switch"
                aria-label={showPassword ? 'hide' : 'show'}
                aria-expanded={showPassword}
                onClick={() => setShowPassword(!showPassword)}
                size="sm"
                color="secondary"
                type="button"
                variant="text"
                icon={ToggleIcon}
                disabled={disabled}
              />
            </div>
          )}
          {showError && (
            <div className={errorClassnames}>
              <ExclamationCircleIcon className="h-5 w-5 text-red-600" aria-hidden="true" />
            </div>
          )}
        </div>
        {showError && <p className="mt-2 text-sm font-normal text-red-700">{error}</p>}
      </label>
    );
  }
);

InputPassword.displayName = 'InputPassword';
