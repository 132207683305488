import React from 'react';
import type { NotificationTypeSchema, NotificationSchema } from '@shape-construction/api/model';
import {
  ArrowUturnLeftIcon,
  AtSymbolIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ChatPrivateIcon,
  CheckCircleIcon,
  ClockIcon,
  FolderIcon,
  NoSymbolIcon,
  UserCircleIcon,
} from '@shape-construction/arch-ui/src/Icons/solid';
import { type NotificationIconColorType } from '@shape-construction/arch-ui/src/NotificationCard/NotificationIcon';
import type { ShiftReportCommsChannel } from 'app/queries/shiftReports/comments/comments';

const buildNotificationsIssueUrl = (projectId: string, issueId: string) =>
  `/projects/${projectId}/issues/${issueId}`;

const buildShiftReportCommentsUrl = (
  projectId: string,
  shiftReportId: string,
  channel: ShiftReportCommsChannel
) => `/projects/${projectId}/shift-reports/${shiftReportId}/${channel}`;

const buildProjectUrl = (projectId: string) => `/projects/${projectId}`;

export type NotificationDecoratedType = NotificationSchema & {
  body?: string;
  title: string;
  url: string;
  batchTitle?: string;
  bodyExtra?: string;
  inAppTitle?: JSX.Element | string;
  tag?: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  color: NotificationIconColorType;
};

type NotificationDecoratorType = {
  [key in NotificationTypeSchema | 'ping']: (
    notification: NotificationSchema
  ) => NotificationDecoratedType;
};

type NotificationTitleProps = {
  actor: string;
  action: string;
  object?: string;
};

export const NOTIFICATION_ICON_TYPES: Record<
  NotificationTypeSchema,
  React.FC<React.SVGProps<SVGSVGElement>>
> = {
  added_to_project: FolderIcon,
  you_were_assigned_to_issue: UserCircleIcon,
  your_issue_assignment_was_accepted: UserCircleIcon,
  your_issue_assignment_was_rejected: UserCircleIcon,
  your_issue_was_reassigned: UserCircleIcon,
  new_issue_comment: ChatBubbleOvalLeftEllipsisIcon,
  new_issue_private_comment: ChatPrivateIcon,
  new_shift_report_comment: ChatBubbleOvalLeftEllipsisIcon,
  issue_comment_mention: AtSymbolIcon,
  shift_report_comment_mention: AtSymbolIcon,
  issue_needs_your_approval: ClockIcon,
  your_issue_approval_request_was_rejected: NoSymbolIcon,
  your_issue_approval_request_was_accepted: CheckCircleIcon,
  issue_was_resolved: CheckCircleIcon,
  issue_was_reopened: ArrowUturnLeftIcon,
};

export const NOTIFICATION_ICON_COLORS: Record<NotificationTypeSchema, NotificationIconColorType> = {
  added_to_project: 'indigo',
  you_were_assigned_to_issue: 'indigo',
  your_issue_assignment_was_accepted: 'green',
  your_issue_assignment_was_rejected: 'red',
  your_issue_was_reassigned: 'indigo',
  new_issue_comment: 'yellow',
  new_issue_private_comment: 'yellow',
  new_shift_report_comment: 'yellow',
  issue_comment_mention: 'pink',
  shift_report_comment_mention: 'pink',
  issue_needs_your_approval: 'indigo',
  your_issue_approval_request_was_rejected: 'red',
  your_issue_approval_request_was_accepted: 'green',
  issue_was_resolved: 'green',
  issue_was_reopened: 'indigo',
};

const NotificationTitle = ({ actor, action, object }: NotificationTitleProps) => {
  return (
    <>
      <span className="font-bold"> {actor} </span> {action}{' '}
      {object && <span className="font-bold"> {object} </span>}
    </>
  );
};

export const NotificationDecorator: NotificationDecoratorType = {
  ping: (notification: NotificationSchema) => ({
    title: 'Ping',
    body: 'Pong',
    icon: NOTIFICATION_ICON_TYPES.added_to_project,
    color: NOTIFICATION_ICON_COLORS.added_to_project,
    url: '',
    ...notification,
  }),

  added_to_project: (notification: NotificationSchema) => ({
    ...notification,
    title: `${notification.params?.actor} has added you to the project ${notification.params?.projectTitle}`,
    inAppTitle: (
      <NotificationTitle
        actor={notification.params?.actor || ''}
        action="has added you to the project"
        object={notification.params?.projectTitle}
      />
    ),
    body: 'Click here to view the project and start collaborating.',
    icon: NOTIFICATION_ICON_TYPES.added_to_project,
    color: NOTIFICATION_ICON_COLORS.added_to_project,
    url: buildProjectUrl(notification.params?.projectId),
  }),

  you_were_assigned_to_issue: (notification: NotificationSchema) => ({
    ...notification,
    title: `${notification.params?.actor} has assigned you the issue ${notification.params?.projectTitle} `,
    inAppTitle: (
      <NotificationTitle
        actor={notification.params?.actor || ''}
        action="has assigned you the issue"
        object={notification.params?.issueTitle}
      />
    ),
    icon: NOTIFICATION_ICON_TYPES.you_were_assigned_to_issue,
    color: NOTIFICATION_ICON_COLORS.you_were_assigned_to_issue,
    url: buildNotificationsIssueUrl(notification.params?.projectId, notification.params?.issueId),
  }),

  your_issue_assignment_was_accepted: (notification: NotificationSchema) => ({
    ...notification,
    title: `${notification.params?.actor} has accepted an issue you assigned ${notification.params?.projectTitle}`,
    inAppTitle: (
      <NotificationTitle
        actor={notification.params?.actor || ''}
        action="has accepted an issue you assigned"
        object={notification.params?.issueTitle}
      />
    ),
    icon: NOTIFICATION_ICON_TYPES.your_issue_assignment_was_accepted,
    color: NOTIFICATION_ICON_COLORS.your_issue_assignment_was_accepted,
    url: buildNotificationsIssueUrl(notification.params?.projectId, notification.params?.issueId),
  }),

  your_issue_assignment_was_rejected: (notification: NotificationSchema) => ({
    ...notification,
    title: `${notification.params?.actor} has rejected an issue you assigned ${notification.params?.projectTitle}`,
    inAppTitle: (
      <NotificationTitle
        actor={notification.params?.actor || ''}
        action="has rejected an issue you assigned"
        object={notification.params?.issueTitle}
      />
    ),
    icon: NOTIFICATION_ICON_TYPES.your_issue_assignment_was_rejected,
    color: NOTIFICATION_ICON_COLORS.your_issue_assignment_was_rejected,
    url: buildNotificationsIssueUrl(notification.params?.projectId, notification.params?.issueId),
  }),

  your_issue_was_reassigned: (notification: NotificationSchema) => ({
    ...notification,
    title: `${notification.params?.actor} re-assigned an issue you created assigned ${notification.params?.projectTitle}`,
    inAppTitle: (
      <NotificationTitle
        actor={notification.params?.actor || ''}
        action="re-assigned an issue you created assigned"
        object={notification.params?.issueTitle}
      />
    ),
    icon: NOTIFICATION_ICON_TYPES.your_issue_was_reassigned,
    color: NOTIFICATION_ICON_COLORS.your_issue_was_reassigned,
    url: buildNotificationsIssueUrl(notification.params?.projectId, notification.params?.issueId),
  }),

  new_issue_comment: (notification: NotificationSchema) => ({
    ...notification,
    title: `${notification.params?.actor} left a comment on the issue ${notification.params?.issueTitle}`,
    inAppTitle: (
      <NotificationTitle
        actor={notification.params?.actor || ''}
        action="left a comment on the issue"
        object={notification.params?.issueTitle}
      />
    ),
    bodyExtra: `"${notification.params?.message}"`,
    tag: `new_issue_comment-${notification.params?.issueId}`,
    batchTitle: `${notification.actor?.name} left the latest comment on ${notification.params?.issueTitle}`,
    icon: NOTIFICATION_ICON_TYPES.new_issue_comment,
    color: NOTIFICATION_ICON_COLORS.new_issue_comment,
    url: buildNotificationsIssueUrl(notification.params?.projectId, notification.params?.issueId),
  }),

  new_issue_private_comment: (notification: NotificationSchema) => ({
    ...notification,
    title: `${notification.params?.actor} left a private comment on the issue ${notification.params?.issueTitle}`,
    inAppTitle: (
      <NotificationTitle
        actor={notification.params?.actor || ''}
        action="left a private comment on the issue"
        object={notification.params?.issueTitle}
      />
    ),
    bodyExtra: `"${notification.params?.message}"`,
    tag: `new_issue_private_comment-${notification.params?.issueId}`,
    batchTitle: `${notification.actor?.name} left the latest private comment on ${notification.params?.issueTitle}`,
    icon: NOTIFICATION_ICON_TYPES.new_issue_private_comment,
    color: NOTIFICATION_ICON_COLORS.new_issue_private_comment,
    url: buildNotificationsIssueUrl(notification.params?.projectId, notification.params?.issueId),
  }),

  new_shift_report_comment: (notification: NotificationSchema) => ({
    ...notification,
    title: `${notification.params?.actor} left a comment on the shift report ${notification.params?.shiftReportTitle || notification.params?.shiftReportDate}`,
    inAppTitle: (
      <NotificationTitle
        actor={notification.params?.actor || ''}
        action="left a comment on the shift report"
        object={notification.params?.shiftReportTitle || notification.params?.shiftReportDate || ''}
      />
    ),
    bodyExtra: `"${notification.params?.message}"`,
    tag: `new_shift_report_${notification.params?.channel}_comment-${notification.params?.shiftReportId}`, // group comments by shift report
    batchTitle: `${notification.actor?.name} left the latest comment on ${notification.params?.shiftReportTitle || notification.params?.shiftReportDate || ''}`,
    icon: NOTIFICATION_ICON_TYPES.new_shift_report_comment,
    color: NOTIFICATION_ICON_COLORS.new_shift_report_comment,
    url: buildShiftReportCommentsUrl(
      notification.params?.projectId,
      notification.params?.shiftReportId,
      notification.params?.channel
    ),
  }),

  issue_comment_mention: (notification: NotificationSchema) => ({
    ...notification,
    title: `${notification.params?.actor} mentioned you on the issue ${notification.params?.issueTitle}`,
    inAppTitle: (
      <NotificationTitle
        actor={notification.params?.actor || ''}
        action="mentioned you on the issue"
        object={notification.params?.issueTitle}
      />
    ),
    bodyExtra: notification.params?.message && `"${notification.params?.message}"`,
    icon: NOTIFICATION_ICON_TYPES.issue_comment_mention,
    color: NOTIFICATION_ICON_COLORS.issue_comment_mention,
    url: buildNotificationsIssueUrl(notification.params?.projectId, notification.params?.issueId),
  }),

  shift_report_comment_mention: (notification: NotificationSchema) => ({
    ...notification,
    title: `${notification.params?.actor} mentioned you on the shift report ${notification.params?.shiftReportTitle || notification.params?.shiftReportDate}`,
    inAppTitle: (
      <NotificationTitle
        actor={notification.params?.actor || ''}
        action="mentioned you on the shift report"
        object={notification.params?.shiftReportTitle || notification.params?.shiftReportDate || ''}
      />
    ),
    bodyExtra: notification.params?.message && `"${notification.params?.message}"`,
    icon: NOTIFICATION_ICON_TYPES.shift_report_comment_mention,
    color: NOTIFICATION_ICON_COLORS.shift_report_comment_mention,
    url: buildShiftReportCommentsUrl(
      notification.params?.projectId,
      notification.params?.shiftReportId,
      notification.params?.channel
    ),
  }),

  issue_needs_your_approval: (notification: NotificationSchema) => ({
    ...notification,
    title: `The issue ${notification.params?.issueTitle} needs your approval`,
    inAppTitle: (
      <NotificationTitle
        actor=""
        action="The issue"
        object={`${notification.params?.issueTitle} needs your approval`}
      />
    ),
    icon: NOTIFICATION_ICON_TYPES.issue_needs_your_approval,
    color: NOTIFICATION_ICON_COLORS.issue_needs_your_approval,
    url: buildNotificationsIssueUrl(notification.params?.projectId, notification.params?.issueId),
  }),

  your_issue_approval_request_was_rejected: (notification: NotificationSchema) => ({
    ...notification,
    title: `${notification.params?.actor} rejected your approval request for the issue ${notification.params?.issueTitle}`,
    inAppTitle: (
      <NotificationTitle
        actor={notification.params?.actor || ''}
        action="rejected your approval request for the issue"
        object={notification.params?.issueTitle}
      />
    ),
    bodyExtra: `Reason: "${notification.params?.reason.trim()}"`,
    icon: NOTIFICATION_ICON_TYPES.your_issue_approval_request_was_rejected,
    color: NOTIFICATION_ICON_COLORS.your_issue_approval_request_was_rejected,
    url: buildNotificationsIssueUrl(notification.params?.projectId, notification.params?.issueId),
  }),

  your_issue_approval_request_was_accepted: (notification: NotificationSchema) => ({
    ...notification,
    title: `${notification.params?.actor} (Approver ${notification.params?.progress}) approved the issue ${notification.params?.issueTitle}`,
    inAppTitle: (
      <>
        <span className="font-bold"> {notification.params?.actor} </span>{' '}
        {`(Approver ${notification.params?.progress})`} approved the issue{' '}
        <span className="font-bold"> {notification.params?.issueTitle}</span>
      </>
    ),
    icon: NOTIFICATION_ICON_TYPES.your_issue_approval_request_was_accepted,
    color: NOTIFICATION_ICON_COLORS.your_issue_approval_request_was_accepted,
    url: buildNotificationsIssueUrl(notification.params?.projectId, notification.params?.issueId),
  }),

  issue_was_resolved: (notification: NotificationSchema) => ({
    ...notification,
    title: `${notification.params?.actor} (Approver ${notification.params?.progress}) approved the issue ${notification.params?.issueTitle}`,
    inAppTitle: (
      <NotificationTitle
        actor={notification.params?.actor || ''}
        action={`(Approver ${notification.params?.progress}) approved the issue`}
        object={notification.params?.issueTitle}
      />
    ),
    body: 'Issue resolved!',
    icon: NOTIFICATION_ICON_TYPES.issue_was_resolved,
    color: NOTIFICATION_ICON_COLORS.issue_was_resolved,
    url: buildNotificationsIssueUrl(notification.params?.projectId, notification.params?.issueId),
  }),

  issue_was_reopened: (notification: NotificationSchema) => ({
    ...notification,
    title: `${notification.params?.actor} reopened the issue ${notification.params?.issueTitle}`,
    inAppTitle: (
      <NotificationTitle
        actor={notification.params?.actor || ''}
        action="reopened the issue"
        object={notification.params?.issueTitle}
      />
    ),
    icon: NOTIFICATION_ICON_TYPES.issue_was_reopened,
    color: NOTIFICATION_ICON_COLORS.issue_was_reopened,
    url: buildNotificationsIssueUrl(notification.params?.projectId, notification.params?.issueId),
  }),
};
