import React, { PropsWithChildren } from 'react';
import { useMessage } from '@messageformat/react';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import FileThumbnail, {
  FileThumbnailProps,
} from '@shape-construction/arch-ui/src/FileThumbnail/FileThumbnail';
import { SkeletonCard } from '@shape-construction/arch-ui/src/Skeleton';
import { GalleryDocument } from '../../hooks/useDocumentsGallery';
import {
  ClearFilters,
  GalleryFilterFormValues,
  ResponsiveGalleryFilterOptions,
} from '../GalleryToolbar/GalleryFilterForm';
import { GallerySortOrderSelect } from '../GalleryToolbar/GalleryFilterFormFields';

export type Params = { projectId: string };

export interface GalleryGridProps extends PropsWithChildren {
  size?: FileThumbnailProps['size'];
  query?: 'media' | 'container';
}

export const GalleryGrid: React.FC<GalleryGridProps> = ({ children, size, query = 'media' }) => {
  return (
    <div
      className={classNames('grid auto-rows-min ', {
        'gap-2 grid-cols-3 sm:grid-cols-6 md:grid-cols-8 lg:grid-cols-10 xl:grid-cols-12':
          size === 'small',
        'gap-3 grid-cols-2 sm:grid-cols-4 lg:grid-cols-6': size === 'large' && query === 'media',
        'gap-3 grid-cols-2 @sm:grid-cols-4 @lg:grid-cols-6':
          size === 'large' && query === 'container',
      })}
      data-testid="gallery-grid"
    >
      {children}
    </div>
  );
};

export type GalleryGridItemProps = {
  document: GalleryDocument;
  onSelect?: (id: NonNullable<GalleryDocument>['id']) => void;
  index: number;
  as?: FileThumbnailProps['as'];
};

export const GalleryGridItem = ({ document, onSelect, index, as }: GalleryGridItemProps) => {
  if (!document) {
    return (
      <img
        className="w-full object-cover rounded-lg pointer-events-none select-none"
        src="/images/placeholders/image-deleted.png"
        alt={`placeholder ${index}`}
        key={`placeholder-${index}`}
      />
    );
  }

  const onClick = onSelect ? () => onSelect(document.id) : undefined;

  return (
    <FileThumbnail
      as={as}
      className="w-full"
      key={`document-${document.id}`}
      extension={document.extension}
      caption={document.caption || document.filename}
      fileId={document.id}
      thumbnailUrl={document.imageUrl?.s}
      onClick={onClick}
      uploadProgress={document.uploadProgress}
      isUploading={document.isUploading}
    />
  );
};

export const GallerySkeleton: React.FC<{ className?: string; length?: number }> = ({
  className,
  length = 1,
}) => {
  return (
    <div
      role="alert"
      aria-label="loading gallery"
      aria-busy="true"
      className={twMerge(
        classNames('grid gap-3 grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 auto-rows-min', className)
      )}
    >
      {Array.from(Array(length).keys()).map((id) => (
        <SkeletonCard key={id} animation="pulse" size="lg" />
      ))}
    </div>
  );
};

type GalleryToolbarProps = PropsWithChildren<{
  className?: string;
  onChange: (values: GalleryFilterFormValues) => void;
  rightSection?: React.ReactNode;
  showSortOrder?: boolean;
}>;

export const GalleryGridToolbar: React.FC<GalleryToolbarProps> = ({
  children,
  className,
  onChange,
  rightSection,
  showSortOrder = true,
}) => {
  return (
    <div
      className={twMerge(
        classNames('bg-gray-50 justify-between flex flex-row py-2 px-4 md:px-8 w-full'),
        className
      )}
    >
      <div className="flex flex-row items-center gap-4 gap-y-2 flex-wrap">
        <ResponsiveGalleryFilterOptions onSubmit={onChange}>
          {children}
        </ResponsiveGalleryFilterOptions>
        <ClearFilters>{useMessage('projectGallery.filters.clearAll')}</ClearFilters>
      </div>
      {showSortOrder && <GallerySortOrderSelect />}
      {rightSection}
    </div>
  );
};
