import React from 'react';
import classNames from 'classnames';
import { Combobox, ComboboxOptionProps } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { PrimitiveProps } from '../../utils/render';

export type SearchOptionProps<TValue> = PrimitiveProps<ComboboxOptionProps<'li', TValue>>;

export function SearchOption<TValue>({ className, ...props }: SearchOptionProps<TValue>) {
  return (
    <Combobox.Option
      className={twMerge(
        classNames(
          'bg-white cursor-pointer w-full px-4 py-1 gap-2.5 flex justify-start items-center ',
          'hover:bg-indigo-50 ui-selected:bg-indigo-100 focus-visible:outline-none ui-disabled:opacity-60 ui-disabled:cursor-not-allowed ui-active:bg-indigo-50'
        ),
        className
      )}
      {...props}
    />
  );
}
SearchOption.displayName = 'Search.Option';
